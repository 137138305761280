import React, { useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as MapboxGL from "mapbox-gl";
import ReactTooltip from "react-tooltip";
import { mdiFilterOff, mdiTable, mdiDotsHorizontal } from "@mdi/js";
import cx from "classnames";
import Icon from "@mdi/react";

import { RootState } from "store/store";
import { LayerType } from "types/mapbox-types";
import {
    ConfigSource,
    LayerActions,
    MapCenter,
    Visibility,
} from "store/map/mapTypes";
import {
    setCenter,
    setLayerFilter,
    setLayerView,
    setLayerVisibility,
    setLegendPopup,
} from "store/map/mapActions";
import Legend from "../../../../Legend/Legend";
import LayerIcon from "../../../../LayerIcon/LayerIcon";
import { Dict } from "types/misgis";
import { isStringArray, isZoomToBBox } from "utils/TypeGuards";
import ScrollableText from "../../../../../../../../_Library/ScrollableText/ScrollableText";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";

import { getCssVar } from "utils/CSSHelpers";
import classes from "./LayerListItem.module.css";
import { WebMercatorViewport } from "react-map-gl";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";
import Toggle from "../../../../../../../../_Library/Inputs/Toggle/Toggle";
import { Badges } from "components/_Library/Badges/Badges";
import { Popover, Tooltip, ActionIcon, Modal } from "@mantine/core";
import { CustomLayerTable } from "./CustomLayerTable/CustomLayerTable";
import LayerContextMenu from "../LayerContextMenu/LayerContextMenu";
import { DownloadsContext } from "components/Pages/Report/Report";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useCurrentEvent } from "hooks/useCurrentEvent";

interface OwnProps {
    id: string;
    layerName: string;
    sourceName: string;
    visibility: Visibility;
    viewOn: "left" | "both" | "right";
    paint: MapboxGL.AnyPaint;
    layout: MapboxGL.AnyLayout;
    layerType: LayerType;
    complexPaintProperties: Array<keyof MapboxGL.AnyPaint>;
    actions?: LayerActions;
    isCustomLayer: boolean;
    isFiltered: boolean;
    beta?: boolean;
    tier?: string;
    downloadsAvailable?: boolean;
}

const LayerListItem: React.FC<OwnProps> = ({
    id,
    layerName,
    sourceName,
    visibility,
    viewOn,
    paint,
    layout,
    layerType,
    complexPaintProperties,
    actions,
    isCustomLayer,
    isFiltered,
    beta,
    tier,
    downloadsAvailable,
}) => {
    const dispatch = useDispatch();
    const [legendOpen, setLegendOpen] = useState(false);
    const [geoDataTableActive, setGeoDataTableActive] = useState(false);
    const highlightedLayer = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "map").highlightedLayer,
    );
    const legendPopup = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "map").legendPopup,
    );
    const sources: Dict<any> = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "map").mapConfig.sources,
    );
    const mapRef = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "ref").mapRef,
    );

    const { trackUserEvent } = useAnalytics();
    const { currentEvent } = useCurrentEvent();
    const { downloadModalOpen } = useContext(DownloadsContext);
    const toggleLayerVisibility = useCallback(() => {
        dispatch(
            setLayerVisibility({
                sourceName,
                layerName,
                visibility: visibility === "visible" ? "none" : "visible",
            }),
        );
        dispatch(
            registerAnalyticsEvent({
                category: "Layers",
                action: layerName + " visibility toggled",
            }),
        );
        trackUserEvent({
            name: "data_layer_visibility_toggled",
            payload: {
                event_id: currentEvent?.id!,
                event_name: currentEvent?.name!,
                layer_id: id,
                layer_name: layerName,
                state: visibility === "visible" ? "off" : "on",
                view: viewOn,
            },
        });
    }, [
        dispatch,
        sourceName,
        layerName,
        visibility,
        trackUserEvent,
        currentEvent?.id,
        currentEvent?.name,
        id,
        viewOn,
    ]);

    const handleZoomToLayer = useCallback(() => {
        let mapCenter: MapCenter = {
            latitude: 0,
            longitude: 0,
            zoom: 0,
        };
        let zoomTo = actions?.zoomTo;

        if (isZoomToBBox(zoomTo)) {
            const bbox = zoomTo.bbox;
            const container: HTMLElement = mapRef.current
                ?.getMap()
                .getContainer();
            const webMercatorViewport = new WebMercatorViewport({
                width: container?.offsetWidth || 800,
                height: container?.offsetHeight || 600,
            });
            let { zoom, latitude, longitude } = webMercatorViewport.fitBounds([
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]],
            ]);
            mapCenter = { zoom, latitude, longitude };
        }

        dispatch(setCenter(mapCenter));
        trackUserEvent({
            name: "go_to_clicked",
            payload: {
                event_id: currentEvent?.id!,
                event_name: currentEvent?.name!,
                layer_id: id,
                layer_name: layerName,
            },
        });
    }, [
        actions?.zoomTo,
        dispatch,
        trackUserEvent,
        currentEvent?.id,
        currentEvent?.name,
        id,
        layerName,
        mapRef,
    ]);

    const renderContextMenu = useCallback(() => {
        return (
            <LayerContextMenu
                onZoomTo={handleZoomToLayer}
                onViewSelection={(view) =>
                    dispatch(
                        setLayerView({ layerName, sourceName, viewOn: view }),
                    )
                }
                currentView={viewOn}
                downloadsAvailable={downloadsAvailable}
                name={sourceName}
            />
        );
    }, [
        layerName,
        sourceName,
        downloadsAvailable,
        handleZoomToLayer,
        viewOn,
        dispatch,
    ]);

    const toggleLegendPopup = useCallback(() => {
        if (legendPopup === null || layerName !== legendPopup.layerName) {
            dispatch(
                setLegendPopup({
                    sourceName,
                    layerName,
                }),
            );
            trackUserEvent({
                name: "layer_legend_pinned",
                payload: {
                    event_id: currentEvent?.id!,
                    event_name: currentEvent?.name!,
                    layer_name: layerName,
                    layer_id: id,
                },
            });
        } else {
            dispatch(setLegendPopup(null));
        }
    }, [
        legendPopup,
        layerName,
        dispatch,
        sourceName,
        trackUserEvent,
        currentEvent?.id,
        currentEvent?.name,
        id,
    ]);

    const rebuildRanges = useCallback(
        (processedExistingFilters: string[], arrayOfSteps: string[]) => {
            let reinstatedRanges: string[][] = [];
            processedExistingFilters.forEach((endRangeNumber: string) => {
                let range = [endRangeNumber];
                let indexOfEndRangeNumber =
                    arrayOfSteps.indexOf(endRangeNumber);
                let startRangeNumber = arrayOfSteps[indexOfEndRangeNumber - 1];
                range.unshift(startRangeNumber);
                reinstatedRanges.push(range);
            });
            return reinstatedRanges;
        },
        [],
    );

    const matchClickedLabelToSelectedLabels = useCallback(
        (selectedLegendRowLabels: string[], clickedLabel: string): string[] => {
            if (selectedLegendRowLabels.includes(clickedLabel)) {
                selectedLegendRowLabels = selectedLegendRowLabels.filter(
                    (existing: string) => existing !== clickedLabel,
                );
            } else {
                selectedLegendRowLabels.push(clickedLabel);
            }
            return selectedLegendRowLabels;
        },
        [],
    );

    const toggleFilter = useCallback(
        (label: string, isInterpolated: boolean, arrayOfSteps: string[]) => {
            let layerSource: ConfigSource = sources[sourceName];
            if (isInterpolated) {
                let arrayOfArrays = sources[sourceName]?.customFilter?.values;
                let processedExistingFilters =
                    arrayOfArrays.map(([a, b]: [string, string]) => b) || [];
                let interimFilters = matchClickedLabelToSelectedLabels(
                    processedExistingFilters,
                    label,
                );
                return rebuildRanges(interimFilters, arrayOfSteps);
            } else {
                if (isStringArray(layerSource?.customFilter?.values)) {
                    return matchClickedLabelToSelectedLabels(
                        [...layerSource?.customFilter?.values],
                        label,
                    );
                } else {
                    return [label];
                }
            }
        },
        [sources, sourceName, matchClickedLabelToSelectedLabels, rebuildRanges],
    );

    const setNewFilter = useCallback(
        (
            label: string,
            featureProperty: string,
            arrayOfSteps: string[],
            isInterpolated: boolean,
        ) => {
            let layerSource: Dict<any> = sources[sourceName];
            let appliedFilters: string[] | string[][] = [];
            if (
                layerSource?.customFilter &&
                layerSource?.customFilter?.identifier === featureProperty
            ) {
                appliedFilters = toggleFilter(
                    label,
                    isInterpolated,
                    arrayOfSteps,
                );
            } else {
                appliedFilters = isInterpolated
                    ? rebuildRanges([label], arrayOfSteps)
                    : [label];
            }
            dispatch(
                setLayerFilter({
                    sourceName,
                    customFilter: {
                        identifier: featureProperty,
                        values: appliedFilters,
                    },
                }),
            );
        },
        [dispatch, sources, sourceName, toggleFilter, rebuildRanges],
    );

    const clearFilter = useCallback(() => {
        if (isFiltered) {
            dispatch(
                setLayerFilter({
                    sourceName,
                    customFilter: { identifier: null, values: [] },
                }),
            );
        }
    }, [dispatch, sourceName, isFiltered]);

    const closeModal = useCallback(() => {
        setGeoDataTableActive(false);
    }, []);

    const renderTable = useCallback(() => {
        return (
            <Modal onClose={closeModal} opened>
                <CustomLayerTable
                    sourceName={sourceName}
                    layerName={layerName}
                />
            </Modal>
        );
    }, [sourceName, layerName, closeModal]);

    const complexLegend = complexPaintProperties.length !== 0;
    const selectedLayerName =
        highlightedLayer !== null ? highlightedLayer.layerName : null;
    const highlight = layerName === selectedLayerName;
    const customFilter = sources[sourceName]?.customFilter;

    const tourId = layerName.replace(/ .*/, "");

    return (
        <div
            id={id}
            className={cx(
                classes.LayerItem,
                { [classes.LegendOpen]: legendOpen },
                { [classes.Highlight]: highlight },
            )}
        >
            {geoDataTableActive && renderTable()}
            <div className={classes.Header}>
                <div
                    className={cx(classes.ToggleTitle, {
                        [classes.ToggleTitleWithBadges]:
                            beta || tier !== "basic",
                    })}
                >
                    <Tooltip label={"Toggle Layer Visibility"}>
                        <span className={classes.VisibilityIcon}>
                            <Toggle
                                heightRem={"1.5rem"}
                                active={visibility === "visible"}
                                onClick={toggleLayerVisibility}
                            />
                            <ReactTooltip
                                id={"LayerVisibility"}
                                place={"left"}
                                effect={"solid"}
                            />
                        </span>
                    </Tooltip>
                    <span className={classes.Label}>
                        <ScrollableText text={layerName} />
                    </span>
                </div>

                <div className={classes.Icons}>
                    {(beta || tier !== "basic") && (
                        <Badges beta={beta} tier={tier} />
                    )}

                    {isFiltered && (
                        <Tooltip label={"Remove Filter"}>
                            <div
                                className={classes.LayerMenu}
                                onClick={clearFilter}
                            >
                                <Icon
                                    path={mdiFilterOff}
                                    color={getCssVar(
                                        isFiltered
                                            ? "--highlight-color"
                                            : "--secondary-color",
                                    )}
                                />
                            </div>
                        </Tooltip>
                    )}

                    {isCustomLayer && (
                        <Tooltip label={"Show in table"}>
                            <div
                                className={classes.LayerMenu}
                                onClick={() =>
                                    setGeoDataTableActive(!geoDataTableActive)
                                }
                            >
                                <Icon
                                    path={mdiTable}
                                    color={getCssVar(
                                        geoDataTableActive
                                            ? "--highlight-color"
                                            : "--text-color",
                                    )}
                                />
                            </div>
                        </Tooltip>
                    )}

                    <div
                        className={classes.LayerIcon}
                        id={"tourIdlayer" + tourId}
                        onClick={
                            complexLegend
                                ? (e) => {
                                      setLegendOpen(!legendOpen);
                                      dispatch(
                                          registerAnalyticsEvent({
                                              category: "Layers",
                                              action:
                                                  layerName + " legend toggled",
                                          }),
                                      );
                                      e.preventDefault();
                                      trackUserEvent({
                                          name: "layer_legend_clicked",
                                          payload: {
                                              event_id: currentEvent?.id!,
                                              event_name: currentEvent?.name!,
                                              layer_name: layerName,
                                              layer_id: id,
                                          },
                                      });
                                  }
                                : undefined
                        }
                    >
                        <LayerIcon
                            complexLegend={complexLegend}
                            paint={paint}
                            type={layerType}
                        />
                    </div>

                    <Popover
                        position={"top-end"}
                        classNames={{
                            dropdown: classes.ContextMenu,
                        }}
                        disabled={downloadModalOpen}
                    >
                        <Popover.Target>
                            <ActionIcon variant="subtle">
                                <Icon
                                    path={mdiDotsHorizontal}
                                    className={classes.ContextMenuIcon}
                                />
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                            {renderContextMenu()}
                        </Popover.Dropdown>
                    </Popover>
                </div>
            </div>
            {complexLegend && (
                <div className={cx(classes.LegendContainer)}>
                    <Legend
                        key={sourceName}
                        paint={paint}
                        layout={layout}
                        layerId={id}
                        complexPaintProperties={complexPaintProperties}
                        type={layerType}
                        toggleLegendPopup={toggleLegendPopup}
                        layerName={layerName}
                        legendPopup={legendPopup!}
                        parent="layerListItem"
                        filterClick={setNewFilter}
                        isFiltered={isFiltered}
                        customFilter={customFilter}
                        source={"layer"}
                    />
                </div>
            )}
        </div>
    );
};

export default LayerListItem;
