/**
 * Side by side map view. Shows the same thing on both maps often with different styles.
 */

import React, { Component, ReactElement } from "react";

import MapGL from "react-map-gl";

import { MapProps } from "../MapContainer/MapContainer";

import "mapbox-gl/dist/mapbox-gl.css";
import classes from "./DualMap.module.css";
import ControlWrapper from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/ControlWrapper/ControlWrapper";
import { MapConfig } from "store/map/mapTypes";

import { RootState } from "store/store";
import { connect } from "react-redux";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import { getLayers } from "../../../../../../../utils/Layers";
import MapIcons from "assets/images/MapIcons";
import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";
import { CapitaliseWord } from "utils/String";
import { InsightsState } from "store/insights/insightsTypes";
import withAnalytics, { withAnalyticsProps } from "components/_Library/HOC/withAnalytics";
import withCurrentEvent, { withCurrentEventProps } from "components/_Library/HOC/withCurrentEvent";

interface DispatchProps {}

interface StateProps {
    mapConfig: MapConfig;
    insightsViewOn: InsightsState["insightsViewOn"];
    assessmentType: InsightsState["assessmentType"];
    clusterToggle: InsightsState["clusterToggle"];
    locationData: InsightsState["locationData"];
}

type DualMapProps = MapProps & DispatchProps & StateProps & withAnalyticsProps & withCurrentEventProps;

interface DualMapState {
    side: string | null;
}

class DualMap extends Component<DualMapProps, DualMapState> {
    state: DualMapState = {
        side: null,
    };

    removeInvisibleLayersFromInteractiveIds(visibleLayers: ReactElement[]) {
        let interactiveIds: string[] = [];
        let menuIndex = this.props.mapConfig.menuIndex;
        let layersIndexResult = getLayers(menuIndex);
        visibleLayers.forEach((layer: ReactElement) => {
            layersIndexResult.forEach((layerFromIndex) => {
                if (layerFromIndex.layerSource === layer.key) {
                    interactiveIds.push(layerFromIndex.layerName);
                }
            });
        });

        return interactiveIds;
    }

    setPopupSide(e: any, side: string) {
        this.setState({
            side: side,
        });
        this.props.handleMapOnClick(e);
    }

    setMarkerSide(e: MapEvent, side: string) {
        this.setState({
            side: side,
        });
        let ref =
            side === "left"
                ? this.props.leftMapRef.current?.getMap()
                : this.props.rightMapRef.current?.getMap();
        this.props.handleMapOnHover(e, ref);
    }

    componentDidMount() {
        this.loadMapImages();
    }

    componentDidUpdate(prevProps: Readonly<DualMapProps>): void {
        if (prevProps.basemaps !== this.props.basemaps) {
            this.loadMapImages();
        }
    }

    loadMapImages = () => {
        let leftMap = this.props.leftMapRef.current?.getMap();
        let rightMap = this.props.rightMapRef.current?.getMap();
        for (let [imageName, image] of Object.entries(MapIcons)) {
            leftMap.loadImage(image, (err: Error, image: any) => {
                if (err) throw err;
                if (!leftMap.hasImage(imageName)) {
                    leftMap.addImage(imageName, image);
                }
                if (!rightMap.hasImage(imageName)) {
                    rightMap.addImage(imageName, image);
                }
            });
        }
    };

    render() {
        const rightMapViewport = {
            ...this.props.viewport,
            transitionDuration: 0,
        };
        let assessmentLayerName = `${CapitaliseWord(
            this.props.assessmentType,
        )} Assessment`;
        let rightInteractiveInsights: string[] = [];
        let leftInteractiveInsights: string[] = [];

        if (
            this.props.locationData &&
            ["both", "right"].includes(this.props.insightsViewOn)
        ) {
            rightInteractiveInsights.push(assessmentLayerName);
            if (this.props.clusterToggle) {
                rightInteractiveInsights.push("insightsClusterCircle");
            }
        }

        if (
            this.props.locationData &&
            ["both", "left"].includes(this.props.insightsViewOn)
        ) {
            leftInteractiveInsights.push(assessmentLayerName);
            if (this.props.clusterToggle) {
                leftInteractiveInsights.push("insightsClusterCircle");
            }
        }

        const rightLayers: ReactElement[] = this.props.layers.filter((elem) =>
            ["both", "right"].includes(
                this.props.mapConfig.sources[elem.key!].viewOn,
            ),
        );
        const leftLayers: ReactElement[] = this.props.layers.filter((elem) =>
            ["both", "left"].includes(
                this.props.mapConfig.sources[elem.key!].viewOn,
            ),
        );
        const interactiveLayersRight: string[] =
            this.removeInvisibleLayersFromInteractiveIds(rightLayers);
        const interactiveLayersLeft: string[] =
            this.removeInvisibleLayersFromInteractiveIds(leftLayers);

        return (
            <React.Fragment>
                <MapGL
                    reuseMaps
                    clickRadius={2}
                    className={classes.leftMap}
                    // Ignored due to ts falsely stating that this will always be overwritten
                    // @ts-ignore
                    width={"100%"}
                    // @ts-ignore
                    height={"100%"}
                    ref={this.props.leftMapRef}
                    mapboxApiAccessToken={this.props.mapboxToken}
                    interactiveLayerIds={interactiveLayersLeft
                        .concat(this.props.additionalInteractiveLayerIds)
                        .concat(leftInteractiveInsights)}
                    {...this.props.viewport}
                    mapStyle={`mapbox://styles/${this.props.basemaps[0]}`}
                    onViewportChange={this.props.handleViewportChange}
                    onHover={(e: MapEvent) => this.setMarkerSide(e, "left")}
                    onClick={(e) => {
                        this.setPopupSide(e, "left")
                        this.props.analytics.trackUserEvent({
                            name: "map_clicked",
                            payload: {
                                event_id: this.props.currentEvent?.id!,
                                event_name: this.props.currentEvent?.name!,
                            }
                        })
                    }}
                    getCursor={this.props.handleGetCursor}
                    onWheel={this.props.onWheel}
                    onDblClick={this.props.onDblClick}
                    onMouseUp={this.props.onMouseUp}
                    onContextMenu={(event: MapEvent) => {
                        this.props.onContextMenu(this.props.leftMapRef, event);
                    }}
                    dragRotate={false}
                >
                    {leftLayers}
                    {this.props.additionalLayers}
                    {this.state.side === "left" &&
                        this.props.insightsDonutMarker}
                    {["both", "left"].includes(this.props.insightsViewOn) &&
                        this.props.insightLayers}
                    {this.props.mapSearchMarker}
                    {this.state.side === "left" && this.props.popup}
                    {this.state.side === "left" && this.props.contextMenu}

                    <ControlWrapper position={"top-left"}>
                        {this.props.controls.tl}
                    </ControlWrapper>
                    <ControlWrapper position={"bottom-left"}>
                        {this.props.controls.bl}
                    </ControlWrapper>
                </MapGL>
                <MapGL
                    reuseMaps
                    clickRadius={2}
                    className={classes.rightMap}
                    // Ignored due to ts falsely stating that this will always be overwritten
                    // @ts-ignore
                    width={"100%"}
                    // @ts-ignore
                    height={"100%"}
                    ref={this.props.rightMapRef}
                    mapboxApiAccessToken={this.props.mapboxToken}
                    interactiveLayerIds={interactiveLayersRight
                        .concat(this.props.additionalInteractiveLayerIds)
                        .concat(rightInteractiveInsights)}
                    {...rightMapViewport}
                    mapStyle={`mapbox://styles/${this.props.basemaps[1]}`}
                    onViewportChange={this.props.handleViewportChange}
                    onHover={(e: MapEvent) => this.setMarkerSide(e, "right")}
                    onClick={(e) => {
                        this.setPopupSide(e, "right")
                        this.props.analytics.trackUserEvent({
                            name: "map_clicked",
                            payload: {
                                event_id: this.props.currentEvent?.id!,
                                event_name: this.props.currentEvent?.name!,
                            }
                        })
                    }}
                    getCursor={this.props.handleGetCursor}
                    onWheel={this.props.onWheel}
                    onDblClick={this.props.onDblClick}
                    onMouseUp={this.props.onMouseUp}
                    onContextMenu={(event: MapEvent) => {
                        this.props.onContextMenu(this.props.rightMapRef, event);
                    }}
                    dragRotate={false}
                >
                    {rightLayers}
                    {this.props.additionalLayers}
                    {this.state.side === "right" &&
                        this.props.insightsDonutMarker}
                    {["both", "right"].includes(this.props.insightsViewOn) &&
                        this.props.insightLayers}
                    {this.props.mapSearchMarker}
                    {this.state.side === "right" && this.props.popup}
                    {this.state.side === "right" && this.props.contextMenu}

                    <ControlWrapper position={"bottom-right"}>
                        {this.props.controls.br}
                    </ControlWrapper>
                    <ControlWrapper position={"top-right"}>
                        {this.props.controls.tr}
                    </ControlWrapper>
                </MapGL>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    mapConfig: getStoreAtNamespaceKey(state, "map").mapConfig,
    insightsViewOn: getStoreAtNamespaceKey(state, "insights").insightsViewOn,
    assessmentType: getStoreAtNamespaceKey(state, "insights").assessmentType,
    clusterToggle: getStoreAtNamespaceKey(state, "insights").clusterToggle,
    locationData: getStoreAtNamespaceKey(state, "insights").locationData,
});

export default connect(mapStateToProps)(withAnalytics(withCurrentEvent(DualMap)));
