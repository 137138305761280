import React, { FC, useEffect } from "react";
import MapGL from "react-map-gl";
import { useSelector } from "react-redux";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";

import { MapProps } from "../MapContainer/MapContainer";
import ControlWrapper from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/ControlWrapper/ControlWrapper";
import MapIcons from "assets/images/MapIcons";
import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";

import "mapbox-gl/dist/mapbox-gl.css";
import { RootState } from "../../../../../../../store/store";
import { InsightsState } from "store/insights/insightsTypes";
import { CapitaliseWord } from "utils/String";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useCurrentEvent } from "hooks/useCurrentEvent";

interface DispatchProps {}
interface StateProps {
    assessmentType: InsightsState["assessmentType"];
    clusterToggle: InsightsState["clusterToggle"];
}
type SingleMapProps = MapProps & DispatchProps & StateProps;

export const SingleMap: FC<SingleMapProps> = (props: SingleMapProps) => {
    const assessmentType = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").assessmentType,
    );
    const clusterToggle = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").clusterToggle,
    );

    const locationData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").locationData,
    );

    const activeBasemap = props.basemaps[0];

    useEffect(() => {
        let map = props.leftMapRef.current?.getMap();
        for (let [imageName, image] of Object.entries(MapIcons)) {
            map.loadImage(image, (err: Error, image: any) => {
                if (err) throw err;
                if (!map.hasImage(imageName)) {
                    map.addImage(imageName, image);
                }
            });
        }
    }, [props.leftMapRef, activeBasemap]);

    let interactiveInsightsId: string[] = [
        `${CapitaliseWord(assessmentType)} Assessment`,
    ];
    if (clusterToggle && locationData) {
        interactiveInsightsId.push("insightsClusterCircle");
    }

    const map = props.leftMapRef.current?.getMap();

    const { trackUserEvent } = useAnalytics();
    const { currentEvent } = useCurrentEvent();

    return (
        <React.Fragment>
            <MapGL
                reuseMaps
                clickRadius={2}
                // Ignored due to ts falsely stating that this will always be overwritten
                // @ts-ignore
                width={"100%"}
                // @ts-ignore
                height={"100%"}
                ref={props.leftMapRef}
                mapboxApiAccessToken={props.mapboxToken}
                interactiveLayerIds={props.interactiveLayerIds
                    .concat(props.additionalInteractiveLayerIds)
                    .concat(locationData ? interactiveInsightsId : [])}
                {...props.viewport}
                transitionDuration={0}
                mapStyle={`mapbox://styles/${props.basemaps[0]}`}
                onViewportChange={props.handleViewportChange}
                onHover={(e: MapEvent) => {
                    props.handleMapOnHover(e, map);
                }}
                onClick={(e: MapEvent) => {
                    props.handleMapOnClick(e)
                    trackUserEvent({
                        name: "map_clicked",
                        payload: {
                            event_id: currentEvent?.id!,
                            event_name: currentEvent?.name!,
                        }
                    })
                    
                }}
                getCursor={props.handleGetCursor}
                onWheel={props.onWheel}
                onDblClick={props.onDblClick}
                onMouseUp={props.onMouseUp}
                onContextMenu={(event: MapEvent) => {
                    props.onContextMenu(props.leftMapRef, event);
                }}
                dragRotate={false}
            >
                {props.contextMenu}
                {props.layers}
                {props.additionalLayers}
                {props.insightLayers}
                {props.insightsDonutMarker}
                {props.mapSearchMarker}
                {props.popup}

                <ControlWrapper position={"top-left"}>
                    {props.controls.tl}
                </ControlWrapper>
                <ControlWrapper position={"bottom-left"}>
                    {props.controls.bl}
                </ControlWrapper>
                <ControlWrapper position={"bottom-right"}>
                    {props.controls.br}
                </ControlWrapper>
                <ControlWrapper position={"top-right"}>
                    {props.controls.tr}
                </ControlWrapper>
            </MapGL>
        </React.Fragment>
    );
};

export default SingleMap;
