/**
 * Displays a timeline based on appConfig > timelineConfig.
 * A timeline range is set with a start and end date.
 * A format is provided in system for use with moment time lib.
 * The end date is set as the default initial date.
 */

import React, { Component, MouseEvent, RefObject } from "react";
import {
    mdiPageFirst,
    mdiPageLast,
    mdiPause,
    mdiPlay,
    mdiSkipNext,
    mdiSkipPrevious,
    mdiSquareEditOutline,
} from "@mdi/js";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import Icon from "@mdi/react";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import {
    SystemActionTypes,
    TimelineConfig,
} from "../../../../../store/system/systemTypes";
import { setCurrentDate } from "../../../../../store/system/systemActions";
import { clamp, loopInRange } from "../../../../../utils/Maths";

import "react-datepicker/dist/react-datepicker.css";
import classes from "./Timeline.module.css";
import { ThunkDispatch } from "redux-thunk";
import { registerAnalyticsEvent } from "../../../../../store/matomo/matomoActions";
import withAnalytics, { withAnalyticsProps } from "components/_Library/HOC/withAnalytics";
import withCurrentEvent, { withCurrentEventProps } from "components/_Library/HOC/withCurrentEvent";

export interface OwnProps {
    options: TimelineConfig;
}

interface StateProps {}

interface DispatchProps {
    // Change global dashboard date.
    setCurrentDate: typeof setCurrentDate;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}

type TimelineProps = OwnProps & StateProps & DispatchProps & withAnalyticsProps & withCurrentEventProps;

interface TimelineState {
    playing: boolean; // is timeline playing flag
    intervalID: number; // interval ref for use in clearInterval when timeline stop playing.
    percentage: number; // current percentage through timeline.
    dragging: boolean; // is the user currently dragging the timeline flag.
    leftOffset: number; // X position of timeline on page for percentage calculations.
    width: number; // width of timeline for percentage calculations.
    currentDate: number;
}

const HOURS_PER_UPDATE_DEFAULT = 3;

class Timeline extends Component<TimelineProps, TimelineState> {
    state: TimelineState = {
        playing: false,
        intervalID: -1,
        percentage: 99.999, // Not set to 100 due to first forward skip press missing first day otherwise.
        dragging: false,
        leftOffset: 0,
        width: 0,
        currentDate: 0,
    };
    startDate: number;
    endDate: number;
    updatesInRange: number;
    percentPerUpdate: number;
    hoursPerUpdate: number;
    private readonly timelineRef: RefObject<HTMLDivElement> = { current: null };

    constructor(props: TimelineProps) {
        super(props);

        this.hoursPerUpdate =
            this.props.options.hoursPerUpdate ?? HOURS_PER_UPDATE_DEFAULT;

        // parse system dates.
        this.startDate =
            moment(props.options.range.min, props.options.range.format).unix() *
            1000;
        this.endDate =
            moment(props.options.range.max, props.options.range.format).unix() *
            1000;

        // Calculate required numbers for skipping to work.
        this.updatesInRange =
            moment(this.endDate)
                .add(this.hoursPerUpdate, "h")
                .diff(moment(this.startDate), "hours") / this.hoursPerUpdate;
        this.percentPerUpdate = 100 / this.updatesInRange;
        this.state.currentDate = this.getDayFromPercent(this.state.percentage);

        // Ref used to determine element position on page for drag calculations.
        this.timelineRef = React.createRef();

        if (this.updatesInRange < 0) {
            alert("The timeline end-date is before the start-date.");
        }

        window.addEventListener("resize", this.onWindowResize);
    }

    componentDidMount(): void {
        // Set global dashboard date on load.
        this.props.setCurrentDate(this.state.currentDate);
        this.onWindowResize();
    }

    componentWillUnmount(): void {
        window.clearInterval(this.state.intervalID);
    }

    // Skip functions all used to move the current timeline percentage. Stops playing if playing.
    // Updates global date if state's current date has changed.
    skipToPercentage = (percentage: number) => {
        percentage = loopInRange(0, 100, percentage);
        let currentDate = this.getDayFromPercent(percentage);
        if (this.hasDateChanged(currentDate)) {
            this.props.setCurrentDate(currentDate);

            this.props.registerAnalyticsEvent({
                category: "Timeline",
                action: "used date selector",
            });
        }
        if (this.state.playing) {
            this.toggleTimeline();
        }
        this.setState({ percentage, currentDate });
    };

    skipFirst = () => {
        this.skipToPercentage(0);
        this.props.registerAnalyticsEvent({
            category: "Timeline",
            action: "jump to start",
        });
        this.props.analytics.trackUserEvent({
            name: "timeline_clicked",
            payload: {
                event_id: this.props.currentEvent?.id!,
                event_name: this.props.currentEvent?.id!,
                action: "start"
            }
        })
    };
    skipLast = () => {
        this.skipToPercentage(99.999);
        this.props.registerAnalyticsEvent({
            category: "Timeline",
            action: "jump to end",
        });
        this.props.analytics.trackUserEvent({
            name: "timeline_clicked",
            payload: {
                event_id: this.props.currentEvent?.id!,
                event_name: this.props.currentEvent?.id!,
                action: "end"
            }
        })
    };
    skipNext = () => {
        this.skipToPercentage(this.state.percentage + this.percentPerUpdate);
        this.props.registerAnalyticsEvent({
            category: "Timeline",
            action: "next",
        });
        this.props.analytics.trackUserEvent({
            name: "timeline_clicked",
            payload: {
                event_id: this.props.currentEvent?.id!,
                event_name: this.props.currentEvent?.id!,
                action: "skip_forward"
            }
        })
    };
    skipPrevious = () => {
        this.skipToPercentage(this.state.percentage - this.percentPerUpdate);
        this.props.registerAnalyticsEvent({
            category: "Timeline",
            action: "previous",
        });
        this.props.analytics.trackUserEvent({
            name: "timeline_clicked",
            payload: {
                event_id: this.props.currentEvent?.id!,
                event_name: this.props.currentEvent?.id!,
                action: "skip_backwards"
            }
        })
    };

    getDayFromPercent = (percent: number): number => {
        let updates = Math.floor(percent / this.percentPerUpdate);
        return (
            moment(this.startDate)
                .clone()
                .add(updates * this.hoursPerUpdate, "h")
                .unix() * 1000
        );
    };

    // Used to remove text highlighting during drag. Mapbox attributes think they are special and still highlight :(
    preventGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "none";
        document.body.style.userSelect = "none";
    };

    // Restore text selection
    restoreGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "auto";
        document.body.style.userSelect = "auto";
    };

    // Play/Pause timeline.
    // OnPlay creates an interval at 30 fps which moves the timeline.
    // OnPause clears interval.
    toggleTimeline = () => {
        let intervalID: number;

        if (this.state.playing) {
            window.clearInterval(this.state.intervalID);
            intervalID = -1;
            this.props.registerAnalyticsEvent({
                category: "Timeline",
                action: "pause",
            });
            this.props.analytics.trackUserEvent({
                name: "timeline_clicked",
                payload: {
                    event_id: this.props.currentEvent?.id!,
                    event_name: this.props.currentEvent?.id!,
                    action: "pause"
                }
            })
        } else {
            let fps = 30;
            let deltaTime = 1000 / fps;

            intervalID = window.setInterval(() => {
                const increment =
                    this.percentPerUpdate /
                    this.props.options.secondsPerUpdate /
                    deltaTime;
                let percentage = this.state.percentage + increment;
                percentage = loopInRange(0, 100, percentage);
                let currentDate = this.getDayFromPercent(percentage);
                if (this.hasDateChanged(currentDate)) {
                    this.props.setCurrentDate(currentDate);
                }
                this.setState({ percentage, currentDate });
            }, deltaTime);

            this.props.registerAnalyticsEvent({
                category: "Timeline",
                action: "play",
            });
            this.props.analytics.trackUserEvent({
                name: "timeline_clicked",
                payload: {
                    event_id: this.props.currentEvent?.id!,
                    event_name: this.props.currentEvent?.id!,
                    action: "play"
                }
            })
        }

        this.setState((state) => {
            return { playing: !state.playing, intervalID };
        });
    };

    // Checks for date update having been triggered.
    hasDateChanged = (newDate: number) => {
        return newDate !== this.state.currentDate;
    };

    // Listened for once slider has mousedown event.
    // Calculated percentage along timeline of current mouse position.
    onMouseMove = (event: any) => {
        let percentage = clamp(
            0,
            100,
            ((event.clientX - this.state.leftOffset) / this.state.width) * 100,
        );
        let currentDate = this.getDayFromPercent(percentage);
        if (this.hasDateChanged(currentDate)) {
            this.props.setCurrentDate(currentDate);
        }
        this.setState({ currentDate, percentage });
    };

    // Set on slider element.
    onMouseDown = (event: MouseEvent) => {
        this.preventGlobalMouseEvents();

        let percentage = clamp(
            0,
            100,
            ((event.clientX - this.state.leftOffset) / this.state.width) * 100,
        );
        let currentDate = this.getDayFromPercent(percentage);
        if (this.hasDateChanged(currentDate)) {
            this.props.setCurrentDate(currentDate);
        }

        this.props.registerAnalyticsEvent({
            category: "Timeline",
            action: "used timeline scrubber",
        });

        this.setState({ dragging: true, currentDate, percentage });
        document.addEventListener("mouseup", this.onMouseUp);
        document.addEventListener("mousemove", this.onMouseMove);
    };

    // Set on document once dragging has begun.
    onMouseUp = () => {
        this.restoreGlobalMouseEvents();
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("mousemove", this.onMouseMove);
        this.setState({ dragging: false });
    };

    // Used on datepicker element attached to date display.
    onDateChange = (date: Date) => {
        let currentDate = moment(date).unix() * 1000;
        if (this.hasDateChanged(currentDate)) {
            this.props.setCurrentDate(currentDate);
        }
        let percentage =
            (moment(currentDate).diff(this.startDate, "hours") /
                this.hoursPerUpdate /
                this.updatesInRange) *
            100;
        this.setState({ currentDate, percentage });
    };

    onWindowResize = () => {
        let leftOffset = 0;
        let width = 0;
        if (this.timelineRef.current) {
            let rect = this.timelineRef.current.getBoundingClientRect();
            leftOffset = rect.left;
            width = rect.width;
        }
        this.setState({ leftOffset, width });
    };

    render() {
        return (
            <div
                ref={this.timelineRef}
                className={classes.Container}
                id={"tourid_TimelineContainer"}
            >
                <div
                    id={"tourid_TimelineTrack"}
                    className={classes.TimelineContainer}
                    onMouseDown={this.onMouseDown}
                >
                    <div
                        style={{ width: this.state.percentage + "%" }}
                        className={classes.Timeline}
                    >
                        <div className={classes.Slider} />
                    </div>
                </div>
                <div className={classes.DisplayContainer}>
                    <div className={classes.LeftDisplay}>
                        <div
                            id={"tourid_TimelineDatePicker"}
                            className={classes.DatePickerContainer}
                            onClick={() => {
                                this.props.analytics.trackUserEvent({
                                    name: "timeline_date_selector_clicked",
                                    payload: {
                                        event_id: this.props.currentEvent?.id!,
                                        event_name: this.props.currentEvent?.id!,
                                    }
                                })
                            }}
                        >
                            <DatePicker
                                showTimeSelect
                                popperPlacement={"top-start"}
                                popperClassName={classes.DatePicker}
                                className={classes.DatePickerInput}
                                onChange={this.onDateChange}
                                onSelect={this.onDateChange}
                                selected={moment(
                                    this.state.currentDate,
                                ).toDate()}
                                minDate={moment(this.startDate).toDate()}
                                maxDate={moment(this.endDate).toDate()}
                                dateFormat={"dd MMM yyyy HH:mm"}
                                onFocus={
                                    (e) =>
                                        e.target.blur() /* Removes ability to edit date text*/
                                }
                                isClearable={false}
                                timeIntervals={60}
                                timeFormat={"HH:mm"}
                            />
                            <div
                                className={classes.DatePickerEditIcon}
                                data-for={"TimelineTooltip"}
                                data-tip={"Click to Select Date"}
                            >
                                <Icon path={mdiSquareEditOutline} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.CentralDisplay}>
                        <div
                            id={"tourid_TimelineControls"}
                            className={classes.Controls}
                        >
                            <div
                                data-for={"TimelineTooltip"}
                                data-tip={`First: ${moment(
                                    this.startDate,
                                ).format("DD MMM YYYY HH:mm")}`}
                                onClick={this.skipFirst}
                            >
                                <Icon path={mdiPageFirst} />
                            </div>
                            <div
                                data-for={"TimelineTooltip"}
                                data-tip={`Go back ${this.hoursPerUpdate} hours`}
                                onClick={this.skipPrevious}
                            >
                                <Icon path={mdiSkipPrevious} />
                            </div>
                            <div
                                onClick={this.toggleTimeline}
                                data-for={"TimelineTooltip"}
                                data-tip={`Toggle Timeline`}
                            >
                                <Icon
                                    path={
                                        !this.state.playing ? mdiPlay : mdiPause
                                    }
                                    size={1.25}
                                />
                            </div>
                            <div
                                data-for={"TimelineTooltip"}
                                data-tip={`Go forward ${this.hoursPerUpdate} hours`}
                                onClick={this.skipNext}
                            >
                                <Icon path={mdiSkipNext} />
                            </div>
                            <div
                                data-for={"TimelineTooltip"}
                                data-tip={`Last: ${moment(this.endDate).format(
                                    "DD MMM YYYY HH:mm",
                                )}`}
                                onClick={this.skipLast}
                            >
                                <Icon path={mdiPageLast} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.RightDisplay}></div>
                </div>
                <ReactTooltip
                    id={"TimelineTooltip"}
                    place={"top"}
                    effect={"solid"}
                    delayShow={300}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    setCurrentDate: bindActionCreators(setCurrentDate, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(null, mapDispatchToProps)(withAnalytics(withCurrentEvent(Timeline)));
