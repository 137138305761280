import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Icon } from "@mdi/react";
import { mdiArrowLeftBold } from "@mdi/js";
import ReactTooltip from "react-tooltip";

import {
    AppConfig,
    DashboardView,
    DashboardViews,
} from "store/system/systemTypes";
import {
    initiateReport,
    setConfig,
    setDashboardView,
} from "store/system/systemActions";
import Dashboard from "components/Pages/Report/Dashboard/Dashboard";
import { fetchAllEventData } from "../../../store/service/serviceActions";
import {
    setActiveTab,
    setHighlightedLayer,
    setMapMarkerLocation,
} from "../../../store/map/mapActions";
import { registerPageChange } from "../../../store/matomo/matomoActions";
import { RootState } from "../../../store/store";

import classes from "components/Pages/Report/Report.module.css";

interface ReportProps {
    reportConfig: AppConfig;
    eventName?: string;
}

interface DownloadsContextType {
    downloadModalOpen: boolean;
    setDownloadModalOpen: (open: boolean) => void;
    openLayerId: string | null;
    setOpenLayerId: (id: string | null) => void;
}

export const DownloadsContext = React.createContext<DownloadsContextType>({
    downloadModalOpen: false,
    setDownloadModalOpen: () => {},
    openLayerId: null,
    setOpenLayerId: () => {},
});

const Report: React.FC<ReportProps> = ({ reportConfig }) => {
    const dispatch = useDispatch();
    const { oktaAuth } = useOktaAuth();
    const { id, config_id, longitude, latitude, zoom } = useParams<{
        id: string;
        config_id: string;
        longitude: string;
        latitude: string;
        zoom: string;
    }>();
    const location = useLocation();

    const [eventId, setLocalEventId] = useState<string | undefined>(undefined);

    const eventData = useSelector((state: RootState) => state?.service?.eventData);

    const reportId = reportConfig.reportId;
    const preview = location.pathname.includes("preview_report");
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [openLayerId, setOpenLayerId] = useState<string | null>(null);

    const isDashboardView = useCallback((page: string): page is DashboardView => {
        return DashboardViews.includes(page as DashboardView);
    }, []);

    useEffect(() => {
        const initialViewport =
            longitude && latitude && zoom
                ? {
                      latitude: parseFloat(latitude),
                      longitude: parseFloat(longitude),
                      zoom: parseFloat(zoom),
                  }
                : null;

        const page = new URLSearchParams(location.search).get("page");
        dispatch(setDashboardView({
            view: page && isDashboardView(page) ? page : "report",
        }));
        dispatch(setActiveTab("Layer"));
        dispatch(setHighlightedLayer(null));
        dispatch(setMapMarkerLocation(null));

        // If app is loaded directly into report page, fetch event data.
        if (!eventData?.length) {
            const token = oktaAuth.getAccessToken();
            if (token) {
                dispatch(fetchAllEventData(token));
            }
        } else {
            if (location.pathname.includes("/report/")) {
                let currentEvent = eventData.find(
                    (index) => index.id === id
                );
                dispatch(registerPageChange({
                    href: location.pathname,
                    title: `GEO - ${currentEvent?.name}`,
                }));
            } else if (location.pathname.includes("/tutorial/")) {
                dispatch(registerPageChange({
                    href: location.pathname,
                    title: `GEO - Tutorial`,
                }));
            }
        }

        dispatch(initiateReport(initialViewport, reportConfig));
        setLocalEventId(id);

        return () => {
            dispatch(setConfig(null));
        };
    }, [dispatch, id, longitude, latitude, zoom, location, oktaAuth, eventData, isDashboardView, reportConfig]);

    useEffect(() => {
        if (eventData?.length) {
            let currentEvent = eventData.find(
                (index) => index.id === id
            );
            dispatch(registerPageChange({
                href: location.pathname,
                title: `GEO - ${currentEvent?.name}`,
            }));
        }
    }, [dispatch, eventData, id, location.pathname]);

    return (
        <div className={classes.Container}>
            <DownloadsContext.Provider value={{ downloadModalOpen, setDownloadModalOpen, openLayerId, setOpenLayerId }}>
                <Dashboard
                    eventId={eventId}
                    reportId={reportId}
                />
                {preview && (
                    <Link to={`/admin/events/${id}/${config_id}`}>
                        <div
                            className={classes.ReturnButton}
                            data-for={"TextTooltip"}
                            data-tip={"Return to Admin"}
                        >
                            <Icon
                                className={classes.ReturnIcon}
                                path={mdiArrowLeftBold}
                            />
                        </div>
                        <ReactTooltip
                            id={"TextTooltip"}
                            place={"top"}
                            effect={"float"}
                        />
                    </Link>
                )}
            </DownloadsContext.Provider>
        </div>
    );
};

export default Report;