/**
 * Two map set up with a slider. The slider allows one of the maps to be gradually hidden revealing a different map underneath.
 * Typical use case for this is the before/after satellite photography.
 * Maps sit directly above each other. Top map absolutely positioned above below map. Clip path used to hide top map.
 */

import React, { Component, ReactElement } from "react";
import { mdiPanHorizontal } from "@mdi/js";

import { connect } from "react-redux";
import MapGL from "react-map-gl";
import Icon from "@mdi/react";

import { RootState } from "../../../../../../../store/store";
import { MapConfig } from "../../../../../../../store/map/mapTypes";
import { InsightsState } from "../../../../../../../store/insights/insightsTypes";
import { MapProps } from "../MapContainer/MapContainer";
import { clamp } from "../../../../../../../utils/Maths";

import classes from "./CompareMap.module.css";
import "mapbox-gl/dist/mapbox-gl.css";
import ControlWrapper from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/ControlWrapper/ControlWrapper";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import { getLayers } from "../../../../../../../utils/Layers";
import MapIcons from "assets/images/MapIcons";
import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";
import { CapitaliseWord } from "utils/String";
import withAnalytics, { withAnalyticsProps } from "components/_Library/HOC/withAnalytics";
import withCurrentEvent, { withCurrentEventProps } from "components/_Library/HOC/withCurrentEvent";

interface StateProps {
    mapConfig: MapConfig;
    clusterToggle: boolean;
    insightsViewOn: InsightsState["insightsViewOn"];
    assessmentType: InsightsState["assessmentType"];
    insightsFilters: InsightsState["insightsFilters"];
    locationData: InsightsState["locationData"];
}

interface DispatchProps {}

type CompareMapProps = MapProps & StateProps & DispatchProps & withAnalyticsProps & withCurrentEventProps;

interface CompareMapState {
    dragging: boolean;
    sliderPos: number; // slider pos in pixels
    rightMapWidth: number; // Used to ensure the right and left maps stay the same size on resize.
}

class CompareMap extends Component<CompareMapProps, CompareMapState> {
    state = {
        sliderPos: 0,
        dragging: false,
        rightMapWidth: window.innerWidth,
    };

    constructor(props: CompareMapProps) {
        super(props);
        this.state.sliderPos = this.props.mapConfig.viewport.width! / 2;
    }

    componentDidMount() {
        this.loadMapImages();
    }

    loadMapImages = () => {
        let leftMap = this.props.leftMapRef.current?.getMap();
        let rightMap = this.props.rightMapRef.current?.getMap();
        for (let [imageName, image] of Object.entries(MapIcons)) {
            leftMap.loadImage(image, (err: Error, image: any) => {
                if (err) throw err;
                if (!leftMap.hasImage(imageName)) {
                    leftMap.addImage(imageName, image);
                }
                if (!rightMap.hasImage(imageName)) {
                    rightMap.addImage(imageName, image);
                }
            });
        }
    };

    //using componentDidUpdate check if the this.props.basemaps array indeices have changed and rerun the loadMapImages function
    componentDidUpdate(prevProps: CompareMapProps) {
        if (prevProps.basemaps !== this.props.basemaps) {
            this.loadMapImages();
        }
    }

    preventGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "none";
    };

    // Restore text selection
    restoreGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "auto";
    };

    // Drag logic. To be replaced with something similar to timeline's
    onMouseDown = () => {
        this.preventGlobalMouseEvents();

        this.setState({ dragging: true });

        document.addEventListener("mouseup", this.onMouseUp);
        document.addEventListener("mousemove", this.onMouseMove);
    };

    onMouseMove = (event: any) => {
        const node = document.getElementsByClassName("overlays")[0];
        const rect = node.getBoundingClientRect();
        const offset = clamp(
            0,
            // Ensure slider cannot pass through tab menu
            // and sits nicely within view
            rect.right - rect.left,
            event.clientX - rect.left,
        );

        this.setState({ sliderPos: offset });
    };
    onMouseUp = (event: any) => {
        this.restoreGlobalMouseEvents();
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("mousemove", this.onMouseMove);
        this.setState({ dragging: false });
    };

    onMapResize = ({ width }: { width: number }) => {
        this.setState({ rightMapWidth: width, sliderPos: width / 2 });
    };

    removeInvisibleLayersFromInteractiveIds(visibleLayers: ReactElement[]) {
        let interactiveIds: string[] = [];
        let menuIndex = this.props.mapConfig.menuIndex;
        let layersIndexResult = getLayers(menuIndex);
        visibleLayers.forEach((layer: ReactElement) => {
            layersIndexResult.forEach((layerFromIndex) => {
                if (layerFromIndex.layerSource === layer.key) {
                    interactiveIds.push(layerFromIndex.layerName);
                }
            });
        });
        return interactiveIds;
    }

    render() {
        const style = {
            clipPath: `polygon(${this.state.sliderPos}px 0, 100% 0, 100% 100%, ${this.state.sliderPos}px 100%)`, // hides the top map
            position: "absolute",
            top: 0,
            left: 0,
        };

        const rightMapViewport = {
            ...this.props.viewport,
            transitionDuration: 0, // Stops the right map causing render-update loop crash
            width: this.state.rightMapWidth,
        };

        let assessmentLayerName = `${CapitaliseWord(
            this.props.assessmentType,
        )} Assessment`;
        let interactiveInsightsRight: string[] = [];
        let interactiveInsightsLeft: string[] = [];

        if (
            this.props.locationData &&
            ["both", "right"].includes(this.props.insightsViewOn)
        ) {
            interactiveInsightsRight.push(assessmentLayerName);
            if (this.props.clusterToggle) {
                interactiveInsightsRight.push("insightsClusterCircle");
            }
        }

        if (
            this.props.locationData &&
            ["both", "left"].includes(this.props.insightsViewOn)
        ) {
            interactiveInsightsLeft.push(assessmentLayerName);
            if (this.props.clusterToggle) {
                interactiveInsightsLeft.push("insightsClusterCircle");
            }
        }

        const rightLayers: ReactElement[] = this.props.layers.filter((elem) =>
            ["both", "right"].includes(
                this.props.mapConfig.sources[elem.key!].viewOn,
            ),
        );
        const leftLayers: ReactElement[] = this.props.layers.filter((elem) =>
            ["both", "left"].includes(
                this.props.mapConfig.sources[elem.key!].viewOn,
            ),
        );
        const interactiveLayersRight: string[] =
            this.removeInvisibleLayersFromInteractiveIds(rightLayers);
        const interactiveLayersLeft: string[] =
            this.removeInvisibleLayersFromInteractiveIds(leftLayers);
        const rightMap = this.props.rightMapRef.current?.getMap();
        const leftMap = this.props.leftMapRef.current?.getMap();

        return (
            <React.Fragment>
                <div className={classes.compareMap}>
                    <MapGL
                        reuseMaps
                        clickRadius={2}
                        className={classes.leftMap}
                        // Ignored due to ts falsely stating that this will always be overwritten
                        // @ts-ignore
                        width={"100%"}
                        // @ts-ignore
                        height={"100%"}
                        ref={this.props.leftMapRef}
                        mapboxApiAccessToken={this.props.mapboxToken}
                        interactiveLayerIds={interactiveLayersLeft
                            .concat(this.props.additionalInteractiveLayerIds)
                            .concat(interactiveInsightsLeft)}
                        {...this.props.viewport}
                        mapStyle={`mapbox://styles/${this.props.basemaps[0]}`}
                        onResize={this.onMapResize}
                        onViewportChange={this.props.handleViewportChange}
                        onHover={(e: MapEvent) =>
                            this.props.handleMapOnHover(e, leftMap)
                        }
                        onClick={(e: MapEvent) => {
                            this.props.handleMapOnClick(e)
                            this.props.analytics.trackUserEvent({
                                name: "map_clicked",
                                payload: {
                                    event_id: this.props.currentEvent?.id!,
                                    event_name: this.props.currentEvent?.name!,
                                }
                            })
                        }}
                        getCursor={this.props.handleGetCursor}
                        onWheel={this.props.onWheel}
                        onDblClick={this.props.onDblClick}
                        onMouseUp={this.props.onMouseUp}
                        onContextMenu={(event: MapEvent) => {
                            this.props.onContextMenu(
                                this.props.leftMapRef,
                                event,
                            );
                        }}
                        dragRotate={false}
                    >
                        {leftLayers}
                        {this.props.additionalLayers}
                        {this.props.insightsDonutMarker}
                        {["both", "left"].includes(this.props.insightsViewOn) &&
                            this.props.insightLayers}
                        {this.props.mapSearchMarker}
                        {this.props.popup}
                        {this.props.contextMenu}

                        <ControlWrapper position={"top-left"}>
                            {this.props.controls.tl}
                        </ControlWrapper>
                        <ControlWrapper position={"bottom-left"}>
                            {this.props.controls.bl}
                        </ControlWrapper>
                        <ControlWrapper position={"bottom-right"}>
                            {this.props.controls.br}
                        </ControlWrapper>
                        <ControlWrapper position={"top-right"}>
                            {this.props.controls.tr}
                        </ControlWrapper>
                    </MapGL>
                    <MapGL
                        reuseMaps
                        style={style}
                        clickRadius={2}
                        className={classes.rightMap}
                        // Ignored due to ts falsely stating that this will always be overwritten
                        // @ts-ignore
                        width={"100%"}
                        // @ts-ignore
                        height={"100%"}
                        ref={this.props.rightMapRef}
                        mapboxApiAccessToken={this.props.mapboxToken}
                        interactiveLayerIds={interactiveLayersRight
                            .concat(this.props.additionalInteractiveLayerIds)
                            .concat(interactiveInsightsRight)}
                        {...rightMapViewport}
                        mapStyle={`mapbox://styles/${this.props.basemaps[1]}`}
                        onViewportChange={this.props.handleViewportChange}
                        onHover={(e: MapEvent) =>
                            this.props.handleMapOnHover(e, rightMap)
                        }
                        onClick={(e: MapEvent) => {
                            this.props.handleMapOnClick(e)
                            this.props.analytics.trackUserEvent({
                                name: "map_clicked",
                                payload: {
                                    event_id: this.props.currentEvent?.id!,
                                    event_name: this.props.currentEvent?.name!,
                                }
                            })
                        }}
                        getCursor={this.props.handleGetCursor}
                        onWheel={this.props.onWheel}
                        onDblClick={this.props.onDblClick}
                        onMouseUp={this.props.onMouseUp}
                        onContextMenu={(event: MapEvent) => {
                            this.props.onContextMenu(
                                this.props.rightMapRef,
                                event,
                            );
                        }}
                        dragRotate={false}
                    >
                        {rightLayers}
                        {this.props.additionalLayers}
                        {["both", "right"].includes(
                            this.props.insightsViewOn,
                        ) && this.props.insightLayers}
                        {this.props.insightsDonutMarker}
                        {this.props.mapSearchMarker}
                        {this.props.popup}
                        {this.props.contextMenu}

                        <ControlWrapper position={"top-left"}>
                            {this.props.controls.tl}
                        </ControlWrapper>
                        <ControlWrapper position={"bottom-left"}>
                            {this.props.controls.bl}
                        </ControlWrapper>
                        <ControlWrapper position={"bottom-right"}>
                            {this.props.controls.br}
                        </ControlWrapper>
                        <ControlWrapper position={"top-right"}>
                            {this.props.controls.tr}
                        </ControlWrapper>
                    </MapGL>
                </div>
                <div
                    className={classes.sliderContainer}
                    style={{ left: this.state.sliderPos + "px" }}
                >
                    <div
                        onMouseDown={this.onMouseDown}
                        className={classes.slider}
                        id={"tourid_CompareMap_Slider"}
                    >
                        <Icon path={mdiPanHorizontal} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    mapConfig: getStoreAtNamespaceKey(state, "map").mapConfig,
    insightsViewOn: getStoreAtNamespaceKey(state, "insights").insightsViewOn,
    clusterToggle: getStoreAtNamespaceKey(state, "insights").clusterToggle,
    assessmentType: getStoreAtNamespaceKey(state, "insights").assessmentType,
    insightsFilters: getStoreAtNamespaceKey(state, "insights").insightsFilters,
    locationData: getStoreAtNamespaceKey(state, "insights").locationData,
});

export default connect(mapStateToProps)(withAnalytics(withCurrentEvent(CompareMap)));
