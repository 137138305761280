/**
 * Display layers from system into the layer list.
 *
 */

import React, { ChangeEvent, Component, ReactNode } from "react";

import { connect } from "react-redux";

import { RootState } from "../../../../../../../../store/store";
import {
    MapState,
    MapActionTypes,
} from "../../../../../../../../store/map/mapTypes";

import LayerListItem from "./LayerListItem/LayerListItem";

import classes from "./LayersTab.module.css";

import Icon from "@mdi/react";
import { ConfigMenuGroup, ConfigMenuLayer } from "store/system/systemTypes";
import {
    mdiDownload,
    mdiLayersPlus,
    mdiLayersTriple,
    mdiUnfoldLessHorizontal,
    mdiUnfoldMoreHorizontal,
} from "@mdi/js";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getStoreAtNamespaceKey } from "../../../../../../../../store/storeSelectors";
import GroupListItem, {
    GroupListItemHandle,
} from "./GroupListItem/GroupListItem";
import GroupedLayersListItem from "./GroupedLayersListItem/GroupedLayersListItem";
import { registerAnalyticsEvent } from "../../../../../../../../store/matomo/matomoActions";
import InsightsLayerListItem from "./InsightsLayerListItem/InsightsLayerListItem";
import Button from "../../../../../../../_Library/Button/Button";
import { getCssVar } from "../../../../../../../../utils/CSSHelpers";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";
import withCurrentEvent, {
    withCurrentEventProps,
} from "components/_Library/HOC/withCurrentEvent";
import { ActionIcon, Flex, Group, TextInput, Tooltip } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import _ from "lodash";

interface OwnProps {
    setDragAndDropState: (active: boolean) => void;
    toggleDownloadModal: () => void;
}
interface StateProps {
    mapConfig: MapState["mapConfig"];
}
interface DispatchProps {
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}
type LayersTabProps = OwnProps &
    DispatchProps &
    StateProps &
    withAnalyticsProps &
    withCurrentEventProps;

interface LayersTabState {
    searchTerm: string;
}

class LayersTab extends Component<LayersTabProps, LayersTabState> {
    groupRefs: Array<GroupListItemHandle> = [];

    state: LayersTabState = {
        searchTerm: "",
    };
    searchAnalytics = _.debounce((searchTerm: string) => {
        this.props.analytics.trackUserEvent({
            name: "layer_searched",
            payload: {
                event_id: this.props.currentEvent?.id!,
                event_name: this.props.currentEvent?.name!,
                value: searchTerm
            }
        })
    }, 500);

    renderChildren = (
        arrayOfChildren: (ConfigMenuGroup | ConfigMenuLayer)[],
    ): ReactNode => {
        const renderedChildren = arrayOfChildren.map((child) => {
            if (this.state.searchTerm) {
                if (child.type === "layer") {
                    if (
                        child.layerName
                            .toLowerCase()
                            .includes(this.state.searchTerm.toLowerCase())
                    ) {
                        return this.renderLayer(child);
                    } else {
                        return null;
                    }
                } else {
                    return this.renderChildren(child.children);
                }
            } else {
                if (child.type === "layer") {
                    return this.renderLayer(child);
                } else if (child.asLayer) {
                    return this.renderGroupAsLayer(child);
                } else {
                    return this.renderGroup(child);
                }
            }
        });
        return renderedChildren;
    };

    recursivelyFindVisibleChildren(groupConfig: ConfigMenuGroup): boolean {
        let layerSources = this.props.mapConfig.sources;
        let visibleChild = groupConfig.children.find((child) => {
            if (child.type === "layer") {
                return (
                    layerSources[child.layerSource].layout.visibility ===
                    "visible"
                );
            } else if (child.type === "group") {
                return this.recursivelyFindVisibleChildren(child);
            } else {
                return false;
            }
        });
        return visibleChild !== undefined;
    }

    renderGroup(group: ConfigMenuGroup) {
        let childVisible = this.recursivelyFindVisibleChildren(group);
        return (
            <GroupListItem
                ref={(node) => {
                    if (
                        node !== null &&
                        !this.groupRefs.find((ref) => ref === node)
                    ) {
                        this.groupRefs.push(node);
                    }
                }}
                initiallyExpanded={childVisible}
                group={group}
                key={group.id}
                renderChildren={this.renderChildren}
                downloadsAvailable={this.anyDownloadsAvailable(group)}
            />
        );
    }

    renderLayer(inputLayer: ConfigMenuLayer) {
        const { layerName, layerSource, id } = inputLayer;
        let layer = this.props.mapConfig.sources[layerSource!];
        let dataPropertyPresent = !!layer.data;
        const isFiltered: boolean = !!(
            layer?.customFilter && layer?.customFilter?.values!.length > 0
        ); //must be boolean not undefined
        return (
            <LayerListItem
                id={`tourid${id}`}
                key={layerName}
                layerName={layerName}
                sourceName={layerSource}
                actions={layer.actions}
                visibility={layer.layout.visibility}
                viewOn={layer.viewOn}
                paint={layer.paint}
                layout={layer.layout}
                layerType={layer.layerType}
                complexPaintProperties={layer.complexPaintProperties}
                isCustomLayer={dataPropertyPresent}
                isFiltered={isFiltered}
                beta={layer.beta ?? false}
                tier={layer.tier ?? "basic"}
                downloadsAvailable={layer.downloadsAvailable ?? false}
            />
        );
    }

    getLayer = (child: ConfigMenuLayer) => {
        return this.props.mapConfig.sources[
            (child as ConfigMenuLayer).layerSource!
        ];
    };

    anyDownloadsAvailable = (item: ConfigMenuGroup | ConfigMenuLayer): boolean => {
        if (item.type === "layer") {
            return this.getLayer(item as ConfigMenuLayer).downloadsAvailable;
        } else {
            // Recursively look for a layer that has downloads available
            return item.children.some((child: ConfigMenuLayer | ConfigMenuGroup) => this.anyDownloadsAvailable(child));
        }
    }

    renderGroupAsLayer = (inputGroup: ConfigMenuGroup) => {
        const { children } = inputGroup;

        const betaLayers = children.filter(
            (child) => this.getLayer(child as ConfigMenuLayer)?.beta,
        );

        const firstNonBasicLayer = children
            .filter((child) => {
                const layer = this.getLayer(child as ConfigMenuLayer);
                return layer && layer.tier !== "basic";
            })
            .map((child) => {
                const layer = this.getLayer(child as ConfigMenuLayer);
                return layer.tier;
            })[0];


        return (
            <GroupedLayersListItem
                key={inputGroup.id}
                group={inputGroup}
                beta={betaLayers.length > 0}
                tier={firstNonBasicLayer ?? "basic"}
                downloadsAvailable={this.anyDownloadsAvailable(inputGroup)}
            />
        );
    };

    updateSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: event.target.value,
        });
        this.searchAnalytics(event.target.value);
    };

    expandAllGroups = () => {
        this.groupRefs.forEach((groupRef) => groupRef.openGroup());
    };
    collapseAllGroups = () => {
        this.groupRefs.forEach((groupRef) => groupRef.closeGroup());
    };

    render() {
        return (
            <div className={classes.LayersTab} id={"tourid_layersMenu"}>
                <InsightsLayerListItem />
                <div className={classes.LayerHeader}>
                    <Icon path={mdiLayersTriple} /> Data Layers

                    <div className={classes.LayerListActions}>
                        <Group spacing="xs">
                        <Tooltip label={"Download intelligence layers"}>
                            <div>
                                <Button
                                    size={{ height: "3.2rem", width: "3.2rem" }}
                                    highlightBackground
                                    borderRadius={getCssVar("--border-radius-sm")}
                                    onClick={() => {
                                        this.props.toggleDownloadModal();
                                        if (this.props.currentEvent) {
                                            this.props.analytics.trackUserEvent({
                                                name: "data_layers_download_clicked",
                                                payload: {
                                                    event_id:
                                                        this.props.currentEvent.id,
                                                    event_name:
                                                        this.props.currentEvent
                                                            .name,
                                                },
                                            });
                                        }
                                        this.props.registerAnalyticsEvent({
                                            category: "Report",
                                            action: "download data (via Layers Tab)",
                                        });
                                    }}
                                >
                                    <Icon path={mdiDownload} size={1.5} />
                                </Button>
                                </div>
                        </Tooltip>

                        <Tooltip label={"Add your own data"}>
                            <div
                                onClick={() => {
                                    if(this.props.currentEvent){
                                        this.props.analytics.trackUserEvent({
                                            name: "data_layer_upload_clicked",
                                            payload: {
                                                event_id: this.props.currentEvent.id,
                                                event_name: this.props.currentEvent.name,
                                            },
                                        });
                                    }
                                    this.props.registerAnalyticsEvent({
                                        category: "Report",
                                        action: "upload data (via Layers Tab)",
                                    });
                                    this.props.setDragAndDropState(true);
                                }}
                                id="tourid_DataUpload"
                                >
                                <Button
                                    size={{ width: "3.2rem", height: "3.2rem" }}
                                    highlightBackground
                                    borderRadius={getCssVar("--border-radius-sm")}
                                    >
                                    <Icon path={mdiLayersPlus} />
                                </Button>
                            </div>
                        </Tooltip>
                        </Group>
                            
                    </div>
                </div>
                <div className={classes.LayerBody} id={"tourId_LayerBody"}>
                    <Flex justify="space-between">
                        <div className={classes.LayerSearchContainer}>
                            <IconSearch style={{zIndex: 1, position: "absolute"}} size="1.5rem"/>
                                                        <TextInput
                                onChange={this.updateSearchTerm}
                                type="search"
                                placeholder={"Layer Search"}
                                value={this.state.searchTerm}
                                size="sm"
                                styles={{ input: { paddingLeft: "2.5rem", "&::placeholder": {
                                    color: "var(--text-color-lo-cont)",
                                } } }}
                            />
                        </div>
                        <Group noWrap align="flex-start" spacing="0" pl="xs">
                            <Tooltip label={"Collapse All"}>
                                <ActionIcon
                                    size="lg"
                                    c="var(--text-color)"
                                    variant="subtle"
                                    onClick={this.collapseAllGroups}
                                >
                                    <Icon path={mdiUnfoldLessHorizontal} size={1.5}/>
                                </ActionIcon>
                            </Tooltip>
                            <Tooltip label={"Expand All"}>
                                <ActionIcon
                                    size="lg"
                                    c="var(--text-color)"
                                    variant="subtle"
                                    onClick={this.expandAllGroups}
                                >
                                    <Icon path={mdiUnfoldMoreHorizontal} size={1.5}/>
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    </Flex>
                    {this.renderChildren(this.props.mapConfig.menuIndex)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    mapConfig: getStoreAtNamespaceKey(state, "map").mapConfig,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => ({
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withAnalytics(withCurrentEvent(LayersTab)));
