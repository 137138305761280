import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Modal, Stack } from "@mantine/core";

import { RootState } from "../../../../store/store";
import {
    SummaryComponentConfig,
    SummaryConfig,
    TextComponentConfig,
} from "../../../../store/system/systemTypes";
import MapContainer from "../DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import Summary from "components/Pages/Report/DashboardComponents/Summary/Summary";
import {
    setAlert,
    setConfig,
    setDashboardView,
    setTextReportOpen,
} from "../../../../store/system/systemActions";
import { ReportModal } from "../ReportModal";
import { ViewTabs } from "./ViewTabs/ViewTabs";
import Downloads from "../Dashboard/Downloads/Downloads";
import { InsightsHeader } from "../DashboardComponents/Insights/InsightsHeader";
import withAnalytics from "components/_Library/HOC/withAnalytics";
import withCurrentEvent from "components/_Library/HOC/withCurrentEvent";

import classes from "./Dashboard.module.css";
import { DownloadsContext } from "../Report";
import { useLayerDownloadsAPI } from "crud/hooks/data_layers";

interface DashboardProps {
    eventId: string | undefined;
    reportId: string | undefined;
    analytics: any;
    currentEvent: any;
}

const Dashboard: React.FC<DashboardProps> = ({
    eventId,
    reportId,
    analytics,
    currentEvent,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const hasConfig = useSelector(
        (state: RootState) => state?.system?.hasConfig,
    );
    const components = useSelector(
        (state: RootState) => state?.system?.components,
    );
    const dashboardView = useSelector(
        (state: RootState) => state?.system?.dashboardView,
    );
    const showTextReport = useSelector(
        (state: RootState) => state?.system?.textReportOpen,
    );
    const isInsightsLoaded = useSelector(
        (state: RootState) => state.insights?.isInsightsLoaded,
    );
    const insightsType = useSelector(
        (state: RootState) => state.insights?.insightsType,
    );
    const insightsData = useSelector(
        (state: RootState) => state.insights?.insightsData,
    );
    const [insightsViewed, setInsightsViewed] = useState(false);
    const {
        downloadModalOpen,
        setDownloadModalOpen,
        openLayerId,
        setOpenLayerId,
    } = useContext(DownloadsContext);

    // Prefetch layer downloads
    useLayerDownloadsAPI(reportId);

    useEffect(() => {
        return () => {
            dispatch(setConfig(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (
            isInsightsLoaded &&
            dashboardView !== "insights" &&
            dashboardView !== "policy_insights" &&
            insightsType === null
        ) {
            dispatch(
                setAlert({
                    message: "Insights are available",
                    timeout: 5000,
                }),
            );
        }
    }, [isInsightsLoaded, dashboardView, insightsType, dispatch]);

    const toggleDownloadModal = useCallback(() => {
        setDownloadModalOpen(!downloadModalOpen);
    }, [downloadModalOpen, setDownloadModalOpen]);



    const generateDownloads = useCallback(
        (summaryOptions: SummaryConfig) => {
            return (
                <Downloads
                    externalDownloads={summaryOptions.downloads}
                    reportId={reportId}
                    layerId={openLayerId}
                />
            );
        },
        [openLayerId, reportId],
    );

    const renderDownloadModal = useCallback(() => {
        const summaryOptions = (
            components?.find(
                (component) => component.type === "summary",
            ) as SummaryComponentConfig
        ).options;

        return (
            <Modal.Root
                onClose={() => {
                    toggleDownloadModal();
                    setOpenLayerId(null);
                }}
                opened={downloadModalOpen}
                size="auto"
            >
                <Modal.Overlay />
                <Modal.Content
                    className={classes.noXScroll}
                    bg="var(--secondary-color)"
                >
                    <Modal.Header p="xs">
                        <Modal.CloseButton size="lg" mr="0" />
                    </Modal.Header>
                    <Modal.Body
                        mih="calc(min(60rem, 60vh))"
                        w="calc(min(40vw, 60rem))"
                        p="lg"
                    >
                        <Stack align="center" h="100%">
                            {generateDownloads(summaryOptions)}
                        </Stack>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        );
    }, [
        components,
        toggleDownloadModal,
        downloadModalOpen,
        generateDownloads,
        setOpenLayerId,
    ]);

    const textReportOptions = (
        components?.find(
            (component) => component.type === "text",
        ) as TextComponentConfig
    )?.options;

    return (
        <div
            className={cx(classes.AppGrid, {
                [classes.ReportGrid]: dashboardView === "report" && hasConfig,
            })}
        >
            {downloadModalOpen && renderDownloadModal()}
            <div
                className={classes.Component}
                style={{
                    gridArea: "1 / 1 / 4 / 6",
                    display: dashboardView === "report" ? "initial" : "none",
                }}
                key={"map"}
            >
                <MapContainer toggleDownloadModal={toggleDownloadModal} eventInfo={{
                            id: eventId!,
                            name: currentEvent?.name!,
                        }} />
            </div>
            <div
                className={classes.Component}
                style={{
                    gridArea: "1 / 1 / 5 / 6",
                    display: ["insights", "policy_insights"].includes(
                        dashboardView!,
                    )
                        ? "initial"
                        : "none",
                }}
                key={"insights"}
            >
                <InsightsHeader eventId={eventId!} />
            </div>
            <Summary downloadModalOpen={downloadModalOpen} eventId={eventId} />
            {textReportOptions && showTextReport && (
                <ReportModal
                    options={textReportOptions}
                    onClose={() => dispatch(setTextReportOpen(false))}
                />
            )}
            <ViewTabs
                onInsightsClick={() => {
                    if (currentEvent) {
                        analytics.trackUserEvent({
                            name: "insights_tab_clicked",
                            payload: {
                                event_id: currentEvent.id,
                                event_name: currentEvent.name,
                            },
                        });
                    }
                    dispatch(
                        setDashboardView({
                            view: "insights",
                            history,
                        }),
                    );
                    setInsightsViewed(true);
                }}
                onMapClick={() => {
                    if (currentEvent) {
                        analytics.trackUserEvent({
                            name: "map_tab_clicked",
                            payload: {
                                event_id: currentEvent.id,
                                event_name: currentEvent.name,
                            },
                        });
                    }
                    dispatch(
                        setDashboardView({
                            view: "report",
                            history,
                        }),
                    );
                }}
                onReportClick={() => {
                    if (currentEvent) {
                        analytics.trackUserEvent({
                            name: "report_tab_clicked",
                            payload: {
                                event_id: currentEvent.id,
                                event_name: currentEvent.name,
                            },
                        });
                    }
                    dispatch(setTextReportOpen(true));
                }}
                mapActive={dashboardView === "report"}
                insightsActive={["insights", "policy_insights"].includes(
                    dashboardView!,
                )}
                insightsAvailable={Boolean(insightsData) && !insightsViewed}
            />
        </div>
    );
};

export default withAnalytics(withCurrentEvent(Dashboard));
