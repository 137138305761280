import React, { FC, lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import * as H from "history";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import { connect, Provider, useDispatch, useSelector } from "react-redux";

import {
    getHomepageStore,
    getAdminPageStore,
    getReportPageStore,
    getStoreAtNamespaceKey,
} from "../../store/storeSelectors";

import classes from "./App.module.css";
import LoadingScreen from "../_Library/LoadingScreen/LoadingScreen";
import { oktaAuthConfig, oktaSignInConfig } from "../../config";
import TermsOfService from "./Permissions/TermsOfService/TermsOfService";
import { RootState } from "../../store/store";
import { MisgisUserClaims } from "../../types/auth";
import { initiateUser } from "store/user/userActions";
import { registerPageChange } from "../../store/matomo/matomoActions";
import LoginPage from "../Pages/LoginPage/LoginPage";
import EventIdReport from "../Pages/EventIdReport/EventIdReport";
import TestReport from "../Pages/TestReport/TestReport";
import PreviewReport from "../Pages/PreviewReport/PreviewReport";
import TutorialReport from "../Pages/TutorialReport/TutorialReport";
import withMatomoInstance, {
    withMatomoInstanceProps,
} from "../_Library/HOC/withMatomoInstance";
import { ThunkDispatch } from "redux-thunk";
import { MapActionTypes } from "store/map/mapTypes";
import { bindActionCreators } from "redux";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import Error404Page from "components/Pages/404Page/404Page";
import ErrorBoundary from "components/_Library/Errors/ErrorBoundary";
import { useIntercom } from "react-use-intercom";

const Homepage = lazy(() => import("components/Pages/Homepage/Homepage"));
const NavBar = lazy(() => import("components/Partials/NavBar/NavBar"));
const AccountPage = lazy(
    () => import("components/Pages/AccountPage/AccountPage"),
);
const HelpPage = lazy(() => import("components/Pages/HelpPage/HelpPage"));
const AboutUsPage = lazy(
    () => import("components/Pages/AboutUsPage/AboutUsPage"),
);
const AdminPage = lazy(() => import("components/Pages/AdminPage/AdminPage"));
const LearningCentre = lazy(
    () => import("components/Pages/LearningCentre/LearningCentre"),
);
const ResetPasswordPage = lazy(
    () => import("components/Pages/ResetPasswordPage/ResetPasswordPage"),
);

// const SharePage = lazy(() => import("components/Pages/SharePage/SharePage"));

const oktaAuth = new OktaAuth(oktaAuthConfig);

interface AppWithRouterAccessProps extends withMatomoInstanceProps {
    initiateUser: typeof initiateUser;
}

const Router: FC<AppWithRouterAccessProps> = (props) => {
    const history: H.History = useHistory();
    const [loading, setLoading] = useState(true);
    const onAuthRequired = () => {
        history.push("/login");
    };
    const restoreOriginalUri: RestoreOriginalUriFunction = async (
        _oktaAuth,
        originalUri,
    ) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    const dispatch = useDispatch();
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user,
    );

    const location = useLocation().pathname;
    let { identify } = useAnalytics();
    const { boot } = useIntercom();

    if (user) {
        boot({
            userId: user.id, 
            name: user.name,
            email: user.email,
            createdAt: user.createdAt,
            userHash: user.userHash,
            hideDefaultLauncher: true
        });
    }

    useEffect(() => {
        oktaAuth.isAuthenticated().then(async (authenticated) => {
            if (!user && authenticated && location !== "/login") {
                oktaAuth.getUser().then(async (user) => {
                    await props.initiateUser(
                        user as MisgisUserClaims,
                        oktaAuth.getAccessToken() || "",
                        identify,
                    );
                    props.matomoInstance.pushInstruction(
                        "setUserId",
                        `${user.sub} ${user?.company_name}`,
                    );
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }

            if (user) {
                if (
                    ["development", "staging"].includes(
                        import.meta.env.VITE_ENVIRONMENT,
                    ) &&
                    !user.groups.includes("MIS Staff")
                ) {
                    await oktaAuth.revokeAccessToken();
                    await oktaAuth.closeSession();
                    history.push("/login");
                }
                props.matomoInstance.pushInstruction(
                    "setUserId",
                    `${user.sub} ${user?.company_name}`,
                );
            }
        });
    });

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Security
                oktaAuth={oktaAuth}
                onAuthRequired={onAuthRequired}
                restoreOriginalUri={restoreOriginalUri}
            >
                {/*Insecure Routes*/}
                <Route
                    path="/login"
                    render={() => <LoginPage config={oktaSignInConfig} />}
                />
                <Route path="/login/callback" component={LoginCallback} />

                {/*Lazy loaded components */}
                <Suspense fallback={<LoadingScreen />}>
                    <Switch>
                        <Route
                            path="/forgot-password"
                            render={() => <ResetPasswordPage />}
                        />
                        {/* To re-enable after 15/08/24 release */}
                        {/* <Route
                            path="/share/:id"
                            render={(renderProps) => {
                                dispatch(
                                    registerPageChange({
                                        href: renderProps.location.pathname,
                                        title: "GEO - Shared Image",
                                    }),
                                );
                                return (
                                    <ErrorBoundary>
                                        <SharePage />
                                    </ErrorBoundary>
                                );
                            }}
                        /> */}

                        {/*Secure Routes*/}
                        <SecureRoute
                            path="/"
                            exact={true}
                            render={(renderProps) => {
                                dispatch(
                                    registerPageChange({
                                        href: renderProps.location.pathname,
                                        title: "GEO - Home",
                                    }),
                                );
                                return (
                                    <Provider store={getHomepageStore()}>
                                        <ErrorBoundary>
                                            <div
                                                className={
                                                    classes.PageContainer
                                                }
                                            >
                                                <NavBar />
                                                <Homepage />
                                            </div>
                                        </ErrorBoundary>
                                    </Provider>
                                );
                            }}
                        />

                        <SecureRoute
                            exact
                            path="/report/:id/:longitude?/:latitude?/:zoom?"
                            render={(routeProps) => (
                                <Provider store={getReportPageStore()}>
                                    <ErrorBoundary>
                                        <div className={classes.PageContainer}>
                                            <NavBar />
                                            <EventIdReport
                                                key={routeProps.match.params.id}
                                            />
                                        </div>
                                    </ErrorBoundary>
                                </Provider>
                            )}
                        />
                        {user?.is_admin && (
                            <SecureRoute
                                path="/test_report"
                                render={() => (
                                    <Provider store={getReportPageStore()}>
                                        <ErrorBoundary>
                                            <div
                                                className={ 
                                                    classes.PageContainer
                                                }
                                            >
                                                <NavBar />
                                                <TestReport />
                                            </div>
                                        </ErrorBoundary>
                                    </Provider>
                                )}
                            />
                        )}
                        {user?.is_admin && (
                            <SecureRoute
                                path="/preview_report/:id/:config_id"
                                render={() => (
                                    <Provider store={getReportPageStore()}>
                                        <ErrorBoundary>
                                            <div
                                                className={
                                                    classes.PageContainer
                                                }
                                            >
                                                <NavBar />
                                                <PreviewReport />
                                            </div>
                                        </ErrorBoundary>
                                    </Provider>
                                )}
                            />
                        )}

                        <SecureRoute
                            path="/tutorial/:tutorialName/:longitude?/:latitude?/:zoom?"
                            render={() => (
                                <Provider store={getReportPageStore()}>
                                    <ErrorBoundary>
                                        <div className={classes.PageContainer}>
                                            <NavBar />
                                            <TutorialReport />
                                        </div>
                                    </ErrorBoundary>
                                </Provider>
                            )}
                        />

                        <SecureRoute
                            path="/account"
                            render={(renderProps) => {
                                dispatch(
                                    registerPageChange({
                                        href: renderProps.location.pathname,
                                        title: "GEO - My Account",
                                    }),
                                );
                                return user ? (
                                    <ErrorBoundary>
                                        <AccountPage />
                                    </ErrorBoundary>
                                ) : null;
                            }}
                        />
                        <SecureRoute
                            path="/about"
                            render={(renderProps) => {
                                dispatch(
                                    registerPageChange({
                                        href: renderProps.location.pathname,
                                        title: "GEO - About us",
                                    }),
                                );
                                return <AboutUsPage />;
                            }}
                        />

                        <SecureRoute
                            path="/learningcentre"
                            render={(renderProps) => {
                                dispatch(
                                    registerPageChange({
                                        href: renderProps.location.pathname,
                                        title: "GEO - Learning Centre",
                                    }),
                                );
                                return (
                                    <ErrorBoundary>
                                        <LearningCentre />
                                    </ErrorBoundary>
                                );
                            }}
                        />

                        <SecureRoute
                            path="/help"
                            render={(renderProps) => {
                                dispatch(
                                    registerPageChange({
                                        href: renderProps.location.pathname,
                                        title: "GEO - Help",
                                    }),
                                );
                                return (
                                    <ErrorBoundary>
                                        <HelpPage />
                                    </ErrorBoundary>
                                );
                            }}
                        />
                        {user?.is_admin && (
                            <SecureRoute
                                path="/admin"
                                render={(renderProps) => {
                                    dispatch(
                                        registerPageChange({
                                            href: renderProps.location.pathname,
                                            title: "GEO - Admin",
                                        }),
                                    );
                                    return (
                                        <Provider store={getAdminPageStore()}>
                                            <ErrorBoundary>
                                                <AdminPage />
                                            </ErrorBoundary>
                                        </Provider>
                                    );
                                }}
                            />
                        )}
                        <SecureRoute
                            path={"*"}
                            render={() => (
                                <Provider store={getAdminPageStore()}>
                                    <NavBar />
                                    <Error404Page />
                                </Provider>
                            )}
                        />
                    </Switch>
                </Suspense>
                {/* Inside Router due to requirement for okta*/}
                <TermsOfService />
            </Security>
        </>
    );
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => ({
    initiateUser: bindActionCreators(initiateUser, dispatch),
});
export default connect(null, mapDispatchToProps)(withMatomoInstance(Router));
