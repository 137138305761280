import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js/commonjs/mdi";
import cx from "classnames";
import { featureCollection, point } from "@turf/helpers";
import bbox from "@turf/bbox";
import { WebMercatorViewport } from "react-map-gl";

import { ConfigMenuGroup } from "../../../../../../../../../store/system/systemTypes";
import {
    ConfigSource,
    Visibility,
} from "../../../../../../../../../store/map/mapTypes";
import {
    setCenter,
    setLayerView,
    setLayerVisibility,
} from "../../../../../../../../../store/map/mapActions";
import ScrollableText from "../../../../../../../../_Library/ScrollableText/ScrollableText";
import LayerIcon from "../../../../LayerIcon/LayerIcon";

import classes from "../LayerListItem/LayerListItem.module.css";
import { isMapCenter } from "../../../../../../../../../utils/TypeGuards";
import Toggle from "../../../../../../../../_Library/Inputs/Toggle/Toggle";
import { Badges } from "components/_Library/Badges/Badges";
import { ActionIcon, Popover, Tooltip } from "@mantine/core";

import LayerContextMenu from "../LayerContextMenu/LayerContextMenu";
import { DownloadsContext } from "components/Pages/Report/Report";
import { useCurrentEvent } from "hooks/useCurrentEvent";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

interface GroupedLayersListItemProps {
    group: ConfigMenuGroup;
    beta?: boolean;
    tier?: string;
    downloadsAvailable?: boolean;
}

const GroupedLayersListItem: React.FC<GroupedLayersListItemProps> = ({ group, beta, tier, downloadsAvailable }) => {
    const dispatch = useDispatch();
    const [containedSources, setContainedSources] = useState<ConfigSource[]>([]);
    const [visibility, setVisibility] = useState(true);
    const { downloadModalOpen } = useContext(DownloadsContext);
    const { currentEvent } = useCurrentEvent();
    const { trackUserEvent } = useAnalytics();
    const collectContainedSources = useCallback((group: ConfigMenuGroup): ConfigSource[] => {
        let sources: ConfigSource[] = [];
        group.children.forEach((child) => {
            if (child.type === "layer") {
                sources.push(sources[(child.layerSource as unknown) as number]);
            } else {
                sources = sources.concat(collectContainedSources(child));
            }
        });
        return sources;
    }, []);

    useEffect(() => {
        const collectedSources = collectContainedSources(group);
        setContainedSources(collectedSources);
        setVisibility(!!collectedSources.find(source => source?.layout?.visibility === "visible"));
    }, [group, collectContainedSources]);

    const toggleLayerVisibility = useCallback((group: ConfigMenuGroup, status: string) => {
        let newVisibility: Visibility = status === "show" ? "visible" : "none";
        let viewOnCount = {
            left: 0,
            both: 0,
            right: 0,
        };
        containedSources.forEach((source) => {
            ++viewOnCount[source.viewOn];
        });
        
        let determinedView = Object.keys(viewOnCount).reduce((a, b) => {
            const keyA = a as keyof typeof viewOnCount;
            const keyB = b as keyof typeof viewOnCount;
            return viewOnCount[keyA] > viewOnCount[keyB] ? keyA : keyB;
          });
        group.children.forEach((child) => {
            if (child.type === "layer") {
                dispatch(setLayerVisibility({
                    sourceName: child.layerSource,
                    layerName: child.layerName,
                    visibility: newVisibility,
                }));
            } else {
                toggleLayerVisibility(child, status);
            }
        });
        setVisibility(newVisibility === "visible");
        trackUserEvent({
            name: "data_layer_visibility_toggled",
            payload: {
                event_id: currentEvent?.id!,
                event_name: currentEvent?.name!,
                layer_id: group.id,
                layer_name: group.groupName,
                state: visibility ? "off" : "on",
                view: determinedView as "left" | "both" | "right",
            }
        })
    }, [containedSources, currentEvent?.id, currentEvent?.name, dispatch, trackUserEvent, visibility]);

    const handleZoomToLayer = useCallback(() => {
        const geometries = containedSources
            .filter((source) => source?.actions?.zoomTo)
            .map((source) => {
                if (isMapCenter(source?.actions!.zoomTo)) {
                    return point([source?.actions!.zoomTo.longitude, source?.actions!.zoomTo.latitude]);
                } else {
                    return point([source?.actions!.zoomTo!.bbox[0], source?.actions!.zoomTo!.bbox[1]]);
                }
            });

        const bBox = bbox(featureCollection(geometries));
        const webMercatorViewport = new WebMercatorViewport({ width: 800, height: 600 });
        let viewport = webMercatorViewport.fitBounds(
            [[bBox[0], bBox[1]], [bBox[2], bBox[3]]],
            { padding: 200 }
        );

        dispatch(setCenter({ 
            latitude: viewport.latitude, 
            longitude: viewport.longitude, 
            zoom: viewport.zoom 
        }));
        trackUserEvent({
            name: "go_to_clicked",
            payload: {
                event_id: currentEvent?.id!,
                event_name: currentEvent?.name!,
                layer_id: group.id,
                layer_name: group.groupName,
            }
        })
    }, [containedSources, currentEvent?.id, currentEvent?.name, dispatch, group.groupName, group.id, trackUserEvent]);

    const setGroupView = useCallback((group: ConfigMenuGroup, view: "left" | "right" | "both") => {
        group.children.forEach((child) => {
            if (child.type === "layer") {
                dispatch(setLayerView({
                    layerName: child.layerName,
                    sourceName: child.layerSource,
                    viewOn: view,
                }));
            } else {
                setGroupView(child, view);
            }
        });
    }, [dispatch]);


    const renderContextMenu = useCallback(() => {
        let viewOnCount = { left: 0, both: 0, right: 0 };
        containedSources.forEach((source) => {
            ++viewOnCount[source?.viewOn];
        });

        const currentView = 
            viewOnCount.left > viewOnCount.both && viewOnCount.left > viewOnCount.right ? 'left' :
            viewOnCount.right > viewOnCount.both ? 'right' : 'both';
        return (
            <LayerContextMenu
                onZoomTo={handleZoomToLayer}
                onViewSelection={(view) => setGroupView(group, view)}
                currentView={currentView}
                name={group.groupName}
                downloadsAvailable={downloadsAvailable}
            />
        );
    }, [containedSources, group, handleZoomToLayer, downloadsAvailable, setGroupView]);

    return (
        <div className={cx(classes.LayerItem)}>
            <div className={classes.Header}>
                <div className={cx(classes.ToggleTitle, {[classes.ToggleTitleWithBadges]: (beta || tier !== "basic")})}>
                    <Tooltip label={"Toggle Layer Visibility"}>
                        <span className={classes.VisibilityIcon}>
                            <Toggle
                                heightRem={"1.5rem"}
                                active={visibility}
                                onClick={() => toggleLayerVisibility(group, visibility ? "hide" : "show")}
                            />
                        </span>
                    </Tooltip>
                    <span className={classes.Label}>
                        <ScrollableText text={group.groupName} />
                    </span>
                </div>
                <div className={classes.Icons}>
                    {(beta || tier !== "basic") && 
                        <Badges beta={beta} tier={tier}/>
                    }
                    <div className={classes.LayerIcon}>
                        <LayerIcon
                            complexLegend={false}
                            paint={{}}
                            type={"raster"}
                        />
                    </div>
                    <Popover
                        position={"top-end"}
                        classNames={{
                            dropdown: classes.ContextMenu
                        }}
                        disabled={downloadModalOpen}
                    >
                        <Popover.Target>
                            <ActionIcon variant="subtle">
                                <Icon
                                    path={mdiDotsHorizontal}
                                    className={classes.ContextMenuIcon}
                                />
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                            {renderContextMenu()}    
                        </Popover.Dropdown>
                    </Popover>
                </div>
            </div>
        </div>
    );
};

export default GroupedLayersListItem;
