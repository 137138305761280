import { MapRef, NavigationControl, ScaleControl } from "react-map-gl";
import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";
import {
    LayerSelectPopupInfo,
    MapContextMenuInfo,
} from "components/Pages/Report/DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import {
    BasemapType,
    LayerInfo,
    MapActionTypes,
    MapConfig,
    ConfigSource,
} from "store/map/mapTypes";
import React, { Component, ReactNode } from "react";
import LayerSelectPopup from "components/Pages/Report/DashboardComponents/Map/Popups/LayerSelectPopup/LayerSelectPopup";

import { minimapCenter } from "utils/Coordinates";
import LatLongControl from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/LatLongControl/LatLongControl";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import classes from "components/_Library/Dropdown/ActionDropdown.module.css";
import {
    setActiveTab,
    setBasemap,
    setClickedFeatureProperties,
    setLegendPopup,
    setMousePosition,
    setHighlightedLayer,
} from "store/map/mapActions";
import { connect } from "react-redux";
import { setAlert } from "store/system/systemActions";
import { RootState } from "store/store";
import {
    InteractionModeMapProps,
    MapInteractions,
} from "components/Pages/Report/DashboardComponents/Map/Mapping/MapInteractions/InteractionModeContainer/InteractionModeContainer";
import Legend from "../../../../Legend/Legend";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";
import {
    clusterLeavesFunc as getClusterLeaves,
    calculateSegmentMagnitudes,
} from "utils/Clusters";
import {
    MapboxGeoJSONFeature,
    Map as MapInstance,
    GeoJSONSource,
} from "mapbox-gl";
import { InsightsState } from "store/insights/insightsTypes";
import { toggleMarker } from "store/insights/insightsActions";
import LabelControl from "../../../Controls/LabelControl/LabelControl";
import MapContextMenu from "../../../../Popups/MapContextMenu/MapContextMenu";

import Minimap from "../../../Controls/BasemapControl/Minimap/Minimap";
import { Place } from "react-tooltip";

export interface OwnProps {
    mapContainerWidth: number;
    updateModeProps: (props: InteractionModeMapProps) => void;
}

interface DispatchProps {
    setMousePosition: typeof setMousePosition;
    setSelectedLayer: typeof setHighlightedLayer; // sets the dataset subset from the selected layer's dataset. for eg, on a country layer with attached dataset, would select the clicked country.
    setAlert: typeof setAlert;
    setClickedFeatureProperties: typeof setClickedFeatureProperties;
    setActiveTab: typeof setActiveTab;
    setBasemap: typeof setBasemap; // for layers which have an api for individual features. This fetches the data.
    setLegendPopup: typeof setLegendPopup;
    toggleMarker: typeof toggleMarker;
}

interface StateProps {
    mapConfig: MapConfig;
    mapboxToken: string;
    basemaps: [BasemapType, BasemapType]; // Basemap styles
    basemapOptions: { [key in BasemapType]: string }; // Basemap styles
    selectedLayer: { layerName: string; sourceName: string } | null;
    legendPopup: { layerName: string; sourceName: string } | null;
    insightsMarker: InsightsState["marker"];
    assessmentType: InsightsState["assessmentType"];
    selectedPeril: InsightsState["selectedPeril"];
}

type InteractionModeStandardProps = StateProps & DispatchProps & OwnProps;

export interface InteractionModeStandardState {
    contextMenuInfo: MapContextMenuInfo;
    popupInfo: LayerSelectPopupInfo;
    basemapControlsExpanded: boolean;
    basemapSecondaryControlsExpanded: boolean;
}

const FEATURE_INFO_LIMIT = 20; // arbitrary limit of 20

class InteractionModeStandard extends Component<
    InteractionModeStandardProps,
    InteractionModeStandardState
> {
    state: InteractionModeStandardState = {
        contextMenuInfo: {
            latitude: 0,
            longitude: 0,
            display: false,
            mapRef: { current: null },
        },
        popupInfo: {
            latitude: 0,
            longitude: 0,
            layers: Array<LayerInfo>(),
        },
        basemapControlsExpanded: false,
        basemapSecondaryControlsExpanded: false,
    };

    toggleBasemapControls = () => {
        this.setState({
            ...this.state,
            basemapControlsExpanded: !this.state.basemapControlsExpanded,
        });

        let offClickListener = (e: MouseEvent) => {
            if (
                !(e.target as HTMLElement).closest(`.${classes.BasemapControl}`)
            ) {
                this.setState({
                    basemapControlsExpanded: false,
                });
                document.removeEventListener("click", offClickListener);
            }
        };
        document.addEventListener("click", offClickListener);
    };

    toggleBasemapSecondaryControls = () => {
        this.setState({
            ...this.state,
            basemapSecondaryControlsExpanded:
                !this.state.basemapSecondaryControlsExpanded,
        });

        let offClickListener = (e: MouseEvent) => {
            if (
                !(e.target as HTMLElement).closest(`.${classes.BasemapControl}`)
            ) {
                this.setState({
                    basemapSecondaryControlsExpanded: false,
                });
                document.removeEventListener("click", offClickListener);
            }
        };
        document.addEventListener("click", offClickListener);
    };

    onContextMenu = (mapRef: React.RefObject<MapRef>, event: MapEvent) => {
        event.preventDefault();
        this.setState({
            contextMenuInfo: {
                latitude: event.lngLat[1],
                longitude: event.lngLat[0],
                display: true,
                mapRef: mapRef,
            },
        });
    };

    handleGetCursor = (state: {
        isLoaded: boolean;
        isDragging: boolean;
        isHovering: boolean;
    }) => {
        // the cursor returned from this function take precedence over marker cursor. So return nothing.
        return state.isHovering ? "pointer" : "default";
    };

    handleMapOnHover = async (event: MapEvent, mapRef?: MapInstance) => {
        this.props.setMousePosition(event.lngLat);
        let cluster: MapboxGeoJSONFeature[] | undefined =
            event.features?.filter((el) => el.properties.cluster);
        if (mapRef && cluster?.length) {
            const clusterId = cluster[0]?.properties?.cluster_id;
            if (this.props.insightsMarker?.clusterId === clusterId) return; // if cluster id has not changed do not compute
            // set clusterID to prevent rerender while large values compute
            this.props.toggleMarker({
                marker: {
                    clusterId,
                },
            });
            const clusterSource = mapRef.getSource(
                cluster[0].source,
            ) as GeoJSONSource;

            let clusterLeaves = getClusterLeaves(
                clusterId,
                cluster[0]?.properties?.point_count,
                clusterSource,
                this.props.assessmentType,
                this.props.selectedPeril!,
            );

            this.props.toggleMarker({
                marker: {
                    lnglat: (cluster[0].geometry as GeoJSON.Point).coordinates,
                    cluster: calculateSegmentMagnitudes(
                        await clusterLeaves,
                        this.props.assessmentType,
                    ),
                    clusterId,
                },
            });
        } else if (this.props.insightsMarker && !cluster?.length) {
            this.props.toggleMarker({
                marker: null,
            });
        }
    };

    handleMapOnClick = (event: MapEvent) => {
        let popupInfo: LayerSelectPopupInfo = { ...this.state.popupInfo };
        this.props.setSelectedLayer(null);
        if (!event?.features) {
            return;
        }

        // ignore clicks not originating in overlays
        if (!event.target.classList.contains("overlays")) {
            return;
        }

        // ignore right button click
        if (event.rightButton) {
            return;
        }

        this.setState((prevState) => ({
            contextMenuInfo: {
                ...prevState.contextMenuInfo,
                display: false,
            },
        }));

        popupInfo.longitude = event.lngLat[0];
        popupInfo.latitude = event.lngLat[1];
        const layers: LayerInfo[] = [];
        const layerNames: string[] = [];
        const clickedFeatureProperties: { [key: string]: any[] } = {};
        if (event.features.length > FEATURE_INFO_LIMIT) {
            event.features = event.features.slice(0, FEATURE_INFO_LIMIT);
        }

        // grab the appropriate data to create the popup interface.
        event.features.forEach((feature) => {
            const featureKeys = Object.keys(feature.properties);
            const keyIndex = featureKeys.findIndex(
                (key) =>
                    key.toLowerCase() === "panorama url" ||
                    key.toLowerCase() === "streetview url",
            ); //This presents a number
            const panoIndex = featureKeys[keyIndex]; //This prints out "Panorama Url" or variant thereof

            if (!layerNames.includes(feature.layer.id)) {
                layerNames.push(feature.layer.id);
                layers.push({
                    name: feature.layer.id,
                    type: feature.layer.type,
                    source: feature.layer.source,
                    paint: feature.layer.paint,
                    panoramaKey: panoIndex,
                });
                clickedFeatureProperties[feature.layer.id] = [
                    feature.properties,
                ];
            } else {
                clickedFeatureProperties[feature.layer.id] = [
                    ...clickedFeatureProperties[feature.layer.id],
                    feature.properties,
                ];
            }
        });

        popupInfo.layers = layers;
        this.setState({ popupInfo });
        this.props.setClickedFeatureProperties(clickedFeatureProperties);
    };

    // -- DATA
    handlePopupOnClose = () => {
        this.setState({ popupInfo: { ...this.state.popupInfo, layers: [] } });
    };

    minimapControls = (position: Place) => {
        const isPrimaryBaseMap = position === "left";
        const mapIdx = isPrimaryBaseMap ? 0 : 1;
        const expanded = isPrimaryBaseMap
            ? this.state.basemapControlsExpanded
            : this.state.basemapSecondaryControlsExpanded;
        const toggleFn = isPrimaryBaseMap
            ? this.toggleBasemapControls
            : this.toggleBasemapSecondaryControls;

        return (
            <div className={classes.BasemapControl} id="tourid_BasemapControl">
                <Minimap
                    border
                    key={"br-basemap-control"}
                    // The active name for this layout should be the first or second index in the basemap props
                    // first index is primary basemap, second is secondary basemap (i.e. for dual, slider)
                    name={
                        this.props.basemapOptions[
                            this.props.basemaps[mapIdx]
                        ] as BasemapType
                    }
                    active={false}
                    mapIndex={1}
                    center={minimapCenter}
                    style={
                        this.props.basemapOptions[this.props.basemaps[mapIdx]]
                    }
                    mapboxToken={this.props.mapboxToken}
                    onClick={toggleFn}
                />
                {expanded && (
                    <div className={classes.BasemapsExpandedContainer}>
                        <div className={classes.BasemapsExpanded}>
                            {Object.keys(this.props.basemapOptions).map(
                                (basemapName: string, idx: number) => {
                                    return (
                                        <div
                                            key={basemapName}
                                            className={
                                                classes.IndividualBasemap
                                            }
                                        >
                                            <Minimap
                                                key={basemapName}
                                                name={
                                                    basemapName as BasemapType
                                                }
                                                active={
                                                    this.props.basemaps[
                                                        mapIdx
                                                    ] === basemapName
                                                }
                                                mapIndex={idx}
                                                center={minimapCenter}
                                                style={
                                                    this.props.basemapOptions[
                                                        basemapName as BasemapType
                                                    ]
                                                }
                                                onClick={() => {
                                                    this.props.setBasemap({
                                                        mapIndex: mapIdx,
                                                        basemap:
                                                            basemapName as BasemapType,
                                                    });
                                                }}
                                                mapboxToken={
                                                    this.props.mapboxToken
                                                }
                                                tooltipPlacement={"top"}
                                                border={false}
                                            />
                                            <p className={classes.BasemapName}>
                                                {basemapName}
                                            </p>
                                        </div>
                                    );
                                },
                            )}
                        </div>
                        {isPrimaryBaseMap && (
                            <div className={classes.LabelControlContainer}>
                                <LabelControl />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    // this weird object returned so map types can chose which controls to display where.
    createControls = (): { [key: string]: ReactNode } => {
        const topLeftControls = [
            <NavigationControl showCompass={false} key={"tl-navigation-control"} />,
        ];
        const bottomLeftControls = [
            this.minimapControls("left"),
            <ScaleControl key={"bl-scale-control"} />,
        ];
        const bottomRightControls = [
            <div key={"br-latlong-control"}>
                <LatLongControl
                    key={"br-latlong-control"}
                    mapContainerWidth={this.props.mapContainerWidth}
                    captureDoubleClick={true}
                    captureClick={true}
                />
            </div>,
        ];

        if (this.props.mapConfig && this.props.mapConfig.mapType !== "single") {
            // For dual / split maps we need controls for the second map.
            bottomRightControls.unshift(this.minimapControls("right"));
        }
        const topRightControls: any = [];
        if (this.props.legendPopup !== null) {
            let source: ConfigSource | undefined;
            if (
                this.props.mapConfig.sources[this.props.legendPopup.sourceName]
            ) {
                source =
                    this.props.mapConfig.sources[
                        this.props.legendPopup.sourceName
                    ];
            }
            topRightControls.unshift(
                <div
                    key={"tr-basemap-control"}
                    style={{
                        marginTop: "3rem",
                        marginRight: "3rem",
                    }}
                >
                    <Legend
                        paint={source!.paint}
                        layout={source!.layout}
                        type={source!.layerType}
                        complexPaintProperties={source!.complexPaintProperties}
                        toggleLegendPopup={this.closeLegendPopup}
                        layerName={this.props.legendPopup.layerName}
                        layerId={source?.dataLayerId}
                        legendPopup={this.props.legendPopup}
                        parent="InteractionModeStandard"
                        isFiltered={false}
                        customFilter={{ identifier: null, values: [] }}
                        source={"layer"}
                    />
                </div>,
            );
        }

        return {
            tl: topLeftControls,
            br: bottomRightControls,
            bl: bottomLeftControls,
            tr: topRightControls,
        };
    };

    closeLegendPopup = () => {
        this.props.setLegendPopup(null);
    };

    createPopup = () => {
        return this.state.popupInfo.layers.length ? (
            <LayerSelectPopup
                popupInfo={this.state.popupInfo}
                setActiveTab={this.props.setActiveTab}
                handlePopupOnClose={this.handlePopupOnClose}
            />
        ) : null;
    };

    createContextMenu = () => {
        return this.state.contextMenuInfo.display ? (
            <MapContextMenu
                latitude={this.state.contextMenuInfo.latitude}
                longitude={this.state.contextMenuInfo.longitude}
                zoom={this.state.contextMenuInfo.mapRef.current?.getMap().getZoom()}
                callOnClick={() =>
                    this.setState({
                        contextMenuInfo: {
                            display: false,
                            latitude: 0,
                            longitude: 0,
                            mapRef: this.state.contextMenuInfo.mapRef,
                        },
                    })
                }
                mapRef={this.state.contextMenuInfo.mapRef}
            />
        ) : null;
    };

    getProps(): InteractionModeMapProps {
        const interactions: MapInteractions = {
            handleGetCursor: this.handleGetCursor,
            handleMapOnClick: this.handleMapOnClick,
            handleMapOnHover: this.handleMapOnHover,
            onContextMenu: this.onContextMenu,
        };

        return {
            interactions,
            additionalInteractiveLayerIds: [],
            layers: null,
            popup: this.createPopup(),
            contextMenu: this.createContextMenu(),
            controls: this.createControls(),
        };
    }
    componentDidMount(): void {
        this.props.updateModeProps(this.getProps());
    }
    componentDidUpdate(
        prevProps: Readonly<InteractionModeStandardProps>,
        prevState: Readonly<InteractionModeStandardState>,
        snapshot?: any,
    ): void {
        this.props.updateModeProps(this.getProps());
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        mapConfig: getStoreAtNamespaceKey(state, "map").mapConfig,
        mapboxToken: getStoreAtNamespaceKey(state, "map").mapboxToken!,
        basemaps: getStoreAtNamespaceKey(state, "map").basemaps,
        basemapOptions: getStoreAtNamespaceKey(state, "map").basemapOptions,
        highlightedLayer: getStoreAtNamespaceKey(state, "map").highlightedLayer,
        legendPopup: getStoreAtNamespaceKey(state, "map").legendPopup,
        insightsMarker: getStoreAtNamespaceKey(state, "insights").marker,
        assessmentType: getStoreAtNamespaceKey(state, "insights")
            .assessmentType,
        selectedPeril: getStoreAtNamespaceKey(state, "insights").selectedPeril,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => {
    return {
        setMousePosition: bindActionCreators(setMousePosition, dispatch),
        setSelectedLayer: bindActionCreators(setHighlightedLayer, dispatch),
        setLegendPopup: bindActionCreators(setLegendPopup, dispatch),
        setAlert: bindActionCreators(setAlert, dispatch),
        setClickedFeatureProperties: bindActionCreators(
            setClickedFeatureProperties,
            dispatch,
        ),
        setActiveTab: bindActionCreators(setActiveTab, dispatch),
        setBasemap: bindActionCreators(setBasemap, dispatch),
        toggleMarker: bindActionCreators(toggleMarker, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(InteractionModeStandard);
