import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import {
    Accordion,
    Badge,
    Text,
    Group,
    Box,
    LoadingOverlay,
} from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../../store/system/systemActions";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useCurrentEvent } from "hooks/useCurrentEvent";
import Icon from "@mdi/react";
import { mdiCloudDownload, mdiFolderDownload, mdiLayersTriple } from "@mdi/js";
import { cx } from "@emotion/css";
import classes from "./Downloads.module.css";
import useClickAway from "hooks/useClickAway";
import { useLayerDownloadsAPI } from "crud/hooks/data_layers";

interface LayerDownload {
    name: string;
    id: string;
    layer_id: string;
    parent_names: string;
    beta: boolean;
}

interface TreeNode {
    name: string;
    id: string;
    children: TreeNode[];
    layerData?: LayerDownload;
}

interface DownloadsProps {
    externalDownloads: Record<string, string>;
    reportId: string | undefined;
    layerId: string | null;
}

const Downloads: React.FC<DownloadsProps> = ({
    externalDownloads,
    reportId,
    layerId,
}) => {
    const { oktaAuth } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const { trackUserEvent } = useAnalytics();
    const { currentEvent } = useCurrentEvent();
    const [pathToNode, setPathToNode] = useState<string[]>([]);
    const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
        offset: 60,
        duration: 200,
    });
    const [isPulsing, setIsPulsing] = useState(false);

    useEffect(() => {
        if (layerId) {
            setIsPulsing(true);
        }
    }, [layerId]);

    useClickAway(targetRef, () => {
        setIsPulsing(false);
    });

    const findPathToNode = useCallback(
        (
            nodes: TreeNode[],
            targetId: string,
            path: string[] = [],
        ): string[] => {
            for (const node of nodes) {
                if (
                    node.layerData?.layer_id === targetId ||
                    node.id === targetId
                ) {
                    return [...path, node.layerData?.layer_id || node.id];
                }
                if (node.children.length > 0) {
                    const childPath = findPathToNode(node.children, targetId, [
                        ...path,
                        node.layerData?.layer_id! || node.id,
                    ]);
                    if (childPath.length > 0) {
                        return childPath;
                    }
                }
            }
            return [];
        },
        [],
    );

    const buildTree = useCallback((layers: LayerDownload[]): TreeNode[] => {
        const globalNodeMap = new Map<string, TreeNode>();

        const getOrCreateNode = (name: string, id: string): TreeNode => {
            if (!globalNodeMap.has(id)) {
                globalNodeMap.set(id, { name, id, children: [] });
            }
            return globalNodeMap.get(id)!;
        };

        layers.forEach((layer) => {
            const parentNames = layer.parent_names
                .split(",")
                .filter((name) => name.trim() !== "");

            let currentNode = getOrCreateNode(
                layer.name,
                layer?.layer_id || layer.id,
            );
            currentNode.layerData = layer;

            let parentNode: TreeNode | null = null;
            parentNames.forEach((parentName) => {
                parentNode = getOrCreateNode(parentName, parentName);
                if (
                    !parentNode.children.some(
                        (child) =>
                            child.layerData?.layer_id ===
                            currentNode.layerData?.layer_id,
                    )
                ) {
                    parentNode.children.push(currentNode);
                }
                currentNode = parentNode;
            });
        });
        // Find root nodes (nodes that are not children of any other node)
        const rootNodes = Array.from(globalNodeMap.values()).filter(
            (node) =>
                !Array.from(globalNodeMap.values()).some(
                    (potentialParent) =>
                        potentialParent !== node &&
                        potentialParent.children.includes(node),
                ),
        );
        return rootNodes;
    }, []);

    const { data, isLoading } = useLayerDownloadsAPI(reportId);
    const layerTree = useMemo(() => {
        if (data && data.length > 0) {
            return buildTree(data);
        }
        return [];
    }, [data, buildTree]);

    useEffect(() => {
        if (layerId && layerTree) {
            const path = findPathToNode(layerTree, layerId);
            setPathToNode(path);
            setTimeout(() => scrollIntoView(), 100);
        }
    }, [findPathToNode, layerTree, layerId, scrollIntoView]);

    const handleDownload = async (layerId: string, layerName: string) => {
        try {
            const response = await axios.get(
                `${
                    import.meta.env.VITE_API_ROOT
                }/data/layers/${layerId}/download`,
                { headers: { Authorization: `Bearer ${token}` } },
            );
            window.open(response.data.data.blob_url, "_blank");
            if (currentEvent) {
                trackUserEvent({
                    name: "data_layer_download_clicked",
                    payload: {
                        layerName,
                        dataLayerId: layerId,
                        event_id: currentEvent?.id,
                        event_name: currentEvent.name,
                    },
                });
            }
        } catch (error) {
            console.error("Failed to download layer:", error);
            dispatch(
                setAlert({
                    message: "Failed to download",
                    timeout: 5000,
                    type: "Error",
                }),
            );
        }
    };

    const renderLayerTree = (nodes: TreeNode[]) => {
        const getAccordionItemStyles = (idx: number) => {
            const isLast = idx === nodes.length - 1;
            return {
                style: {
                    borderBottom: isLast
                        ? "none"
                        : "1px solid var(--border-color)",
                },
            };
        };

        const getAccordionControlStyles = (
            isTerminatingNode: boolean = false,
        ) => {
            return {
                styles: {
                    chevron: { display: isTerminatingNode ? "none" : "" },
                },
                p: isTerminatingNode ? "0 1rem" : "",
            };
        };

        const hasNoMoreChildren = (node: TreeNode) =>
            node.children.length === 0;
        const isGroupWithJustOneChild = (node: TreeNode) => {
            return (
                node.children.length === 1 &&
                hasNoMoreChildren(node.children[0])
            );
        };

        return nodes.map((node, idx) =>
            hasNoMoreChildren(node) || isGroupWithJustOneChild(node) ? (
                <Accordion.Item
                    key={node.layerData?.layer_id || node.id}
                    value={node.layerData?.layer_id || node.id}
                    {...getAccordionItemStyles(idx)}
                    ref={
                        node.layerData?.layer_id === layerId
                            ? targetRef
                            : undefined
                    }
                >
                    <Accordion.Control
                        chevron={<></>}
                        {...getAccordionControlStyles(true)}
                        p="0"
                    >
                        <Box
                            className={cx(classes.accordionControlBox, {
                                [classes.pulseAnimation]:
                                    (node.layerData?.layer_id === layerId ||
                                        node.name === layerId) &&
                                    isPulsing,
                            })}
                        >
                            <Group position="apart">
                                <Text>{node.name}</Text>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDownload(
                                            data?.find(
                                                (layer) =>
                                                    // If we've rendered a layer, the layerId
                                                    // should be used as a lookup for the download ID
                                                    // otherwise, we have a nested group with only one item
                                                    // so we should use that, as we're hiding nested layers
                                                    // with one item in the tree as they ugly
                                                    layer.layer_id ===
                                                        node.layerData
                                                            ?.layer_id ||
                                                    layer.layer_id ===
                                                        node.children[0]
                                                            ?.layerData
                                                            ?.layer_id,
                                            )?.id!,
                                            node?.layerData?.name!,
                                        );
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Icon path={mdiCloudDownload} size="2rem" />
                                </div>
                            </Group>
                        </Box>
                    </Accordion.Control>
                </Accordion.Item>
            ) : (
                <Accordion.Item
                    key={node.layerData?.layer_id || node.id}
                    value={node.layerData?.layer_id || node?.id!}
                    {...getAccordionItemStyles(idx)}
                >
                    <Accordion.Control {...getAccordionControlStyles()} p="0">
                        <Box
                            className={cx(classes.accordionControlBox, {
                                [classes.pulseAnimation]:
                                    (node.layerData?.layer_id === layerId ||
                                        node.name === layerId) &&
                                    isPulsing,
                            })}
                        >
                            <Group>
                                <Text>{node.name}</Text>
                                {node.layerData?.beta && (
                                    <Badge color="yellow">Beta</Badge>
                                )}
                            </Group>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Accordion
                            defaultValue={
                                pathToNode.length > 1
                                    ? pathToNode[1]
                                    : undefined
                            }
                        >
                            {renderLayerTree(node.children)}
                        </Accordion>
                    </Accordion.Panel>
                </Accordion.Item>
            ),
        );
    };

    const renderExternalDownloads = () => {
        if (!Object.keys(externalDownloads).length)
            return (
                <Text c="dimmed">
                    There are no downloadable external files available for this
                    event
                </Text>
            );

        const downloadEntries = Object.entries(externalDownloads);

        if (downloadEntries.length === 1) {
            const [filename, url] = downloadEntries[0];
            return (
                <Box className={classes.accordionControlBox}>
                    <Group position="apart">
                        <Text>{filename}</Text>
                        <div
                            onClick={() => {
                                window.open(url, "_blank");
                                if (currentEvent) {
                                    trackUserEvent({
                                        name: "external_downloads_download_clicked",
                                        payload: {
                                            name: filename,
                                            event_id: currentEvent?.id,
                                            event_name: currentEvent.name,
                                        },
                                    });
                                }
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <Icon path={mdiCloudDownload} size="2rem" />
                        </div>
                    </Group>
                </Box>
            );
        }

        return (
            <Accordion>
                {downloadEntries.map(([filename, url]) => (
                    <Accordion.Item key={filename} value={filename}>
                        <Accordion.Control>
                            <Group>
                                <Text>{filename}</Text>
                            </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Group position="apart">
                                <Text>{filename}</Text>
                                <div
                                    onClick={() => {
                                        window.open(url, "_blank");
                                        if (currentEvent) {
                                            trackUserEvent({
                                                name: "external_downloads_download_clicked",
                                                payload: {
                                                    name: filename,
                                                    event_id: currentEvent?.id,
                                                    event_name:
                                                        currentEvent.name,
                                                },
                                            });
                                        }
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Icon path={mdiCloudDownload} size="2rem" />
                                </div>
                            </Group>
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>
        );
    };

    if ((layerId && pathToNode.length === 0) || isLoading)
        return <LoadingOverlay visible />;

    return (
        <Accordion
            w="100%"
            variant="filled"
            className={classes.accordionContainer}
            bg="var(--primary-color)"
            defaultValue={layerId ? "layers" : undefined}
        >
            <Accordion.Item value="layers">
                <Accordion.Control>
                    <Group>
                        <Icon size="2rem" path={mdiLayersTriple} />
                        <Text>Layer Downloads</Text>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <Accordion
                        defaultValue={
                            pathToNode.length > 0 ? pathToNode[0] : null
                        }
                        styles={{ content: { paddingBottom: 0 } }}
                    >
                        {layerTree && layerTree.length > 0 ? (
                            renderLayerTree(layerTree)
                        ) : (
                            <Text c="dimmed">
                                There are no downloadable layer files available
                                for this event
                            </Text>
                        )}
                    </Accordion>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="external">
                <Accordion.Control>
                    <Group>
                        <Icon size="2rem" path={mdiFolderDownload} />
                        <Text>External Downloads</Text>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <Accordion>{renderExternalDownloads()}</Accordion>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};

export default Downloads;
